<template>
    <div :class="{
        container: true,
        'page-main-content': true,
        'page-main-content-compressed': currentEnroll && isCompressed(),
    }" v-if="loading">
        <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->
        <content-placeholders>
            <content-placeholders-img :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
                class="prev-next-placeholder" />
            <content-placeholders-img :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
                class="video-placeholder" />
            <content-placeholders-img :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
                class="video-info-placeholder" />
            <content-placeholders-text :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
                class="class-title-placeholder" :lines="2" />
            <content-placeholders-img :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
                class="accordion-image-placeholder" />
        </content-placeholders>
    </div>
    <div :class="{
        'page-main-content': true,
        'page-main-content-compressed': isCompressed(),
    }" :style="[
        $theme.darkTheme
            ? 'border-left-color: #0d0d13'
            : 'background-color: #f2f3f7; border-left-color: #F2F3F7',
        {
            '--primary-color': $theme.primaryColor,
            '--primary-text': $theme.primaryText,
        },
    ]" v-else>
        <div class="box-menu-navigation" :style="$theme.darkTheme ? '' : 'background-color: #ffffff; color: #ff6600'
            " v-show="!inFullscreen &&
                (currentLearningObject.previousId || currentLearningObject.nextId)
                ">
            <div class="container box-menu-center">
                <div class="menu-previous" :style="currentLearningObject.previousId
                    ? 'cursor:pointer;'
                    : 'cursor:default'
                    " @click="navigatePreviousClass">
                    <template v-if="currentLearningObject.previousId">
                        <!-- <img src="@/assets/icons/previous.svg" alt /> -->
                        <IconPrevious />
                        <span :style="$theme.darkTheme ? '' : 'color: #333333'">Anterior</span>
                    </template>
                </div>
                <div class="menu-next" :style="currentLearningObject.nextId ? 'cursor:pointer;' : 'cursor:default'
                    " @click="navigateNextClass(false)">
                    <template v-if="currentLearningObject.nextId">
                        <span :style="$theme.darkTheme ? '' : 'color: #333333'">Próximo</span>
                        <!-- <img src="@/assets/icons/next.svg" alt /> -->
                        <IconNext />
                    </template>
                </div>
            </div>

            <!-- <NavPrevBt
        hasPreview

        :visible="Boolean(currentLearningObject.previousId)"
        :onClick="navigatePreviousClass"
        :previewImage="currentLearningObject.previousImage"
        :previewTitle="currentLearningObject.previousName"
      />

      <NavNextBt
        hasPreview

        :visible="Boolean(currentLearningObject.nextId)"
        :onClick="navigateNextClass"
        :previewImage="currentLearningObject.nextImage"
        :previewTitle="currentLearningObject.nextName"
      /> -->
        </div>
        <div v-if="!checkCurrentLearningObjectState" class="quiz-result container">
            <div class="row">
                <div class="result-label result-survey">
                    Esta aula ainda não está liberada.
                </div>
            </div>
        </div>

        <div class="container info-title">
            <div class="content-name" :style="$theme.darkTheme ? '' : 'color: #333333'">
                {{ currentLearningObject.title }}
            </div>

            <div class="master-name line-clamp-3" :style="$theme.darkTheme ? '' : 'color: #333333'">
                {{ currentLearningObject.category }}
            </div>
        </div>
        <div class="player2 container" v-if="checkCurrentLearningObjectState && currentLearningObject.broadcast">
            <!-- <NavPrevBt
        centered
        hasPreview
        :visible="Boolean(currentLearningObject.previousId)"
        :onClick="navigatePreviousClass"
        :previewImage="currentLearningObject.previousImage"
        :previewTitle="currentLearningObject.previousName"
      />

      <NavNextBt
        centered
        hasPreview
        :visible="Boolean(currentLearningObject.nextId)"
        :onClick="() => navigateNextClass(false)"
        :previewImage="currentLearningObject.nextImage"
        :previewTitle="currentLearningObject.nextName"
      /> -->

            <!-- Aqui vai o player -->
            <coremedia-player :mute="devMute" :customerId="currentEnroll.webcastIntegration.applicationId"
                :customerToken="currentEnroll.webcastIntegration.accessToken" :learningObject="currentLearningObject"
        :studentEmail="currentUser.studentMail" @ended="handleEnded" @ready="coremediaReady($event)"></coremedia-player>
        </div>
        <div class="container" v-if="checkCurrentLearningObjectState && currentLearningObject.pdf">
            <pdf-viewer :src="getPDFUrl()"></pdf-viewer>
        </div>
        <div class v-if="checkCurrentLearningObjectState && currentLearningObject.forms">
            <Quiz :formId="currentLearningObject.forms.id"></Quiz>
        </div>
        <div class="container" v-if="
            checkCurrentLearningObjectState && currentLearningObject.externalContent
        ">
            <div class="row">
                <iframe style="min-height: 554px" class="col s12 m12 l12"
                    :src="'//' + currentLearningObject.externalContent.link" frameborder="0"></iframe>
            </div>
        </div>
        <!-- <div
      class="container"
      v-if="checkCurrentLearningObjectState && currentLearningObject.htmlContent"
    >
      <div class="row">
        <div class="html-content" v-html="currentLearningObject.htmlContent.content"></div>
      </div>
    </div>-->
        <div class="box-info container" :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'" v-show="checkCurrentLearningObjectState &&
            !inFullscreen &&
            !currentLearningObject.forms
            ">
            <div class="html-content" :style="$theme.darkTheme ? '' : 'color: #333333'" v-if="
                checkCurrentLearningObjectState && currentLearningObject.htmlContent
            " v-html="currentLearningObject.htmlContent.content"></div>

            <div class="info-content">
                <div class="content-flex">
                    <div class="content-rating">
                        <div class="rating-love">
                            <i v-for="i in 5" :key="i" :class="getRatingsClass(i)" @mouseover="ratingsHover = i"
                                @mouseleave="ratingsHover = null" @click="setLearningObjectRating(i)" />
                            <!-- <span :style="$theme.darkTheme ? '' : 'color: #333333'">
              ({{ currentLearningObjectRatings.count }})
            </span> -->
                        </div>
                    </div>
                    <div class="master-status">
                        <label>
              <input type="checkbox" id="btnStatus" class="filled-in" :checked="currentLearningObject.progress == 100"
                @click="setProgress" />
                            <span :style="$theme.darkTheme ? '' : 'color: #333333'">{{
                                currentLearningObject.progress == 100
                                    ? "Concluído"
                                    : "Marcar como concluído"
                            }}
                            </span>
                        </label>
                    </div>
                </div>

                <div class="content-flex">
          <div class="content-downloads dropdown-trigger" :style="$theme.darkTheme ? '' : 'color: #333333'" id="ddx"
            data-target="dropdown-materiais" v-if="
                            currentLearningObject.files && currentLearningObject.files.length
                        ">
                        <!-- <img src="@/assets/icons/download.png" alt /> -->
                        <IconDownload />
                        <p>Arquivos</p>
                        <!-- <div class="preloader-wrapper small active">
            <div v-show="isDownloading()" class="spinner-layer">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div> -->
                    </div>
                    <ul id="dropdown-materiais" class="dropdown-content" v-if="
                        currentLearningObject.files && currentLearningObject.files.length
                    ">
                        <li v-for="(file, fileIndex) in currentLearningObject.files" :key="file.id">
                            <a :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'" href="javascript:;" @click="
                                downloadFile(
                                    file,
                                    fileIndex,
                                    currentLearningObject,
                                    $event.target
                                )
                                ">
                <span v-if="!file.loadingItem && !file.generatedItemLink">[{{ file.fileType.toUpperCase() }}] {{
                                        file.name
                                    }} ({{
                                        file.size | bytes
                                    }})</span>
                                <span v-if="file.loadingItem" class="spinner-small">
                                    <SpinnerWave></SpinnerWave>
                                </span>
                <span v-if="file.generatedItemLink"><a :href="file.generatedItemLink" target="_blank">Clique aqui para
                                        abrir
                                        [{{ file.name }}]</a></span>
                            </a>
                        </li>
                    </ul>

                    <!-- <NavPrevBt
        centered
        hasPreview
        :visible="Boolean(currentLearningObject.previousId)"
        :onClick="navigatePreviousClass"
        :previewImage="currentLearningObject.previousImage"
        :previewTitle="currentLearningObject.previousName"
      />
         -->
          <NavPrevBt hasPreview :visible="Boolean(currentLearningObject.previousId)" :onClick="navigatePreviousClass"
            :previewImage="currentLearningObject.previousImage" :previewTitle="currentLearningObject.previousName" />

                    <NavNextBt hasPreview :visible="Boolean(currentLearningObject.nextId)" :onClick="navigateNextClass"
            :previewImage="currentLearningObject.nextImage" :previewTitle="currentLearningObject.nextName" />

                    <!-- <div class="btn-navigation previous">
          <IconPrevious />
          <span>Anterior</span>
        </div>
        <div class="btn-navigation next">
          <span>Próximo</span>
          <IconNext />
        </div> -->
                </div>
            </div>

            <div :class="{
                'info-details': true,
            }">
                <!-- Informações -->
                <div class="btn-info">
                    <button class="tablink" :class="{ active: tabIndex == 1 }" :style="tabIndex == 1
                        ? {
                            background: $theme.primaryColor,
                            color: $theme.primaryText,
                        }
                        : $theme.darkTheme
                            ? {
                                color: '#ffffff',
                            }
                            : {
                                color: '#131318',
                            }
                        " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="setActive(1, { $event })" id="defaultOpen" v-if="!currentLearningObject.htmlContent">
                        Informações
                    </button>
                    <!-- Comentários -->
                    <button v-if="enableComments" class="tablink" :class="{ active: tabIndex == 2 }" :style="tabIndex == 2
                        ? {
                            background: $theme.primaryColor,
                            color: $theme.primaryText,
                        }
                        : $theme.darkTheme
                            ? {
                                color: '#ffffff',
                            }
                            : {
                                color: '#131318',
                            }
                        " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="setActive(2, { $event })">
                        Comentários
                    </button>
                    <!-- Inteligência Artificial -->
          <button v-if="currentLearningObject.broadcast" id="btn-ia" class="tablink" :class="{ active: tabIndex == 3 }"
            :style="tabIndex == 3
                            ? {
                                background: $theme.primaryColor,
                                color: $theme.primaryText,
                            }
                            : $theme.darkTheme
                                ? {
                                    color: '#ffffff',
                                }
                                : {
                                    color: '#131318',
                                }
                            " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="setActive(3, { $event })">
                        <IconAI />
                        Inteligência Artificial
                    </button>
                </div>

                <!-- Content Informações -->
        <div v-if="!currentLearningObject.htmlContent" v-show="tabIndex === 1" id="Informacoes" class="tabcontent"
          :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                    <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'"
                        v-html="currentLearningObject.description"></div>
                </div>

                <!-- Content Comentários -->
                <div v-if="enableComments" v-show="tabIndex === 2" id="Comentarios" class="tabcontent"
                    :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                    <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                        <CommentsBox level="0" first="true" :comments="this.currentLearningObjectComents"
              :studentId="currentUser.studentId" :studentName="currentUser.studentName" :showReplyBoxId="showReplyBoxId"
              :courseId="currentEnroll.courseId" :learningObjectId="currentLearningObject.id"
              :enrollId="this.currentEnroll.id" />
                    </div>
                </div>

                <!-- Content Inteligência Artificial -->
        <div v-if="currentLearningObject.broadcast" v-show="tabIndex === 3" id="IA" class="tabcontent sub-tabcontent"
          :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'" :class="{
                        'no-transcript':
                            !currentLearningObject.broadcast || !transcriptionsVisible,
                    }">
                    <!-- BTN ABAS IA -->
                    <!-- Transcrição -->
          <button class="sub-tablink" :class="{ active: tabIndex == 3 && subtabIndex == 4 }" @mouseover="changeColor"
            @mouseleave="changeColorToOriginal({ $event })" @click="subSetActive(4, { $event })"
            :id="transcriptionsVisible ? 'defaultOpen' : ''"
                        v-if="currentLearningObject.broadcast && transcriptionsVisible" :style="subtabIndex == 4
                            ? {
                                background: $theme.primaryColor,
                                color: $theme.primaryText,
                                borderTopColor: $theme.primaryColor,
                            }
                            : $theme.darkTheme
                                ? {
                                    color: '#ffffff',
                                    borderBottomColor: $theme.primaryColor,
                                    borderTopColor: $theme.primaryColor,
                                }
                                : {
                                    color: '#131318',
                                    borderBottomColor: $theme.primaryColor,
                                    borderTopColor: $theme.primaryColor,
                                }
                            ">
                        Transcrição
                    </button>

                    <!-- Resumo -->
                    <button class="sub-tablink" :id="transcriptionsVisible ? '' : 'defaultOpen'"
                        :class="{ active: tabIndex == 3 && subtabIndex == 5 }" :style="subtabIndex == 5
                            ? {
                                background: $theme.primaryColor,
                                color: $theme.primaryText,
                                borderTopColor: $theme.primaryColor,
                            }
                            : $theme.darkTheme
                                ? {
                                    color: '#ffffff',
                                    borderTopColor: $theme.primaryColor,
                                }
                                : {
                                    color: '#131318',
                                    borderTopColor: $theme.primaryColor,
                                }
                            " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="subSetActive(5, { $event })">
                        Resumo
                    </button>

                    <!-- Mapa Mental -->
          <button id="btn-mapa" class="sub-tablink" :class="{ active: tabIndex == 3 && subtabIndex == 6 }" :style="subtabIndex == 6
                            ? {
                                background: $theme.primaryColor,
                                color: $theme.primaryText,
                                borderTopColor: $theme.primaryColor,
                            }
                            : $theme.darkTheme
                                ? {
                                    color: '#ffffff',
                                    borderTopColor: $theme.primaryColor,
                                }
                                : {
                                    color: '#131318',
                                    borderTopColor: $theme.primaryColor,
                                }
                            " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="subSetActive(6, { $event })">
                        Mapa Mental
                    </button>

                    <!-- Quiz -->
          <button id="btn-quiz" class="sub-tablink" :class="{ active: tabIndex == 3 && subtabIndex == 7 }" :style="subtabIndex == 7
                            ? {
                                background: $theme.primaryColor,
                                color: $theme.primaryText,
                                borderTopColor: $theme.primaryColor,
                            }
                            : $theme.darkTheme
                                ? {
                                    color: '#ffffff',
                                    borderTopColor: $theme.primaryColor,
                                }
                                : {
                                    color: '#131318',
                                    borderTopColor: $theme.primaryColor,
                                }
                            " @mouseover="changeColor" @mouseleave="changeColorToOriginal({ $event })"
                        @click="subSetActive(7, { $event })">
                        Quiz
                    </button>

                    <!-- BTN ABAS IA -->

                    <!-- Content IA Interno -->

                    <div v-show="subtabIndex === 4" id="Transcricao" class="tabcontent"
                        :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                        <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                            <vd-transcript v-slot="{ search, transcripts, currentIndex, seek }"
                @selectedchanged="handleTranscriptSelectedChange" @searchresultindexchanged="handleSearchResultChanged">
                                <div class="transc-box">
                                    <div class="transc-search pesquisa" :style="$theme.darkTheme
                                        ? ''
                                        : 'background-color: #efefef ; color: #333333'
                                        ">
                                        <div class="nav-wrapper search-box">
                                            <form @submit.prevent>
                                                <div class="input-field">
                          <input id="search-trans" type="search" placeholder class="white-text" required :style="$theme.darkTheme
                                                            ? ''
                                                            : 'background-color: #efefef; color: #333333 !important'
                                                            " :value="searchQuery" @input="
                                                                debounceSearch(
                                                                    $event.target.value,
                                                                    search.setText
                                                                )
                                                                " />
                                                    <label class="label-icon" for="search-trans">
                            <i class="material-icons" :style="{ color: $theme.primaryColor }">search</i>
                                                    </label>
                                                </div>
                                            </form>
                                        </div>
                    <div class="search-results" v-if="search.text" :style="$theme.darkTheme ? '' : 'color: #333333'">
                                            {{ search.currentCount }}/{{ search.totalCount }}
                                        </div>
                                    </div>
                                    <div class="transc-clear">
                                        <a href="javascript:;" @click="search.setText('')">
                                            <i class="material-icons" :style="{ color: $theme.primaryColor }">clear</i>
                                        </a>
                                    </div>

                                    <div class="transc-up">
                                        <a href="javascript:;" @click="search.previousResult()">
                      <i class="material-icons" :style="{ color: $theme.primaryColor }">expand_less</i>
                                        </a>
                                    </div>
                                    <div class="transc-down">
                                        <a href="javascript:;" @click="search.nextResult()">
                      <i class="material-icons" :style="{ color: $theme.primaryColor }">expand_more</i>
                                        </a>
                                    </div>
                                </div>
                <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                  <ul ref="transcripts" class="transcripts" :class="$theme.darkTheme ? 'dark' : 'light'">
                                        <!--
                              -->
                                        <li v-for="(item, index) in transcripts" :key="'transcript' + index"
                                            class="segment" :class="{ current: index == currentIndex }" @click="playAndSeek(item.begin, seek)">
                                            <span class="segment-timestamp">
                                                {{ item.begin | formatTimestamp }}
                                            </span>

                                            <p v-if="item.searchResult">
                        <span v-for="(part, partIndex) in item.parts" :key="'phrase' + index + 'part' + partIndex"
                          :class="{
                                                        filtered:
                                                            part.text.toLowerCase() ==
                                                            search.text.toLowerCase(),
                                                        highlight:
                                                            index == search.currentIndexes.phraseIndex &&
                                                            partIndex == search.currentIndexes.partIndex,
                                                    }">{{ part.text }}</span>
                                            </p>
                                            <p v-else class="segment-text">{{ item.text }}</p>
                                        </li>
                                    </ul>
                                </div>
                            </vd-transcript>
                        </div>
                    </div>

                    <div v-show="subtabIndex === 5" id="Resumo" class="tabcontent"
                        :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                        <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                            <template v-if="aiContent && aiContent.shortSummary">
                                <div class="aiTitle">Síntese</div>
                                <p class="aiContent">
                                    {{ aiContent.shortSummary }}
                                </p>
                            </template>
                            <template v-if="aiContent && aiContent.summary">
                                <div class="aiTitle">Resumo</div>
                                <p class="aiContent">
                                    {{ aiContent.summary }}
                                </p>
                            </template>

                            <StudentClassEmptyTabFallback v-if="
                                !aiContent || !(aiContent.shortSummary || aiContent.summary)
                            " />
                        </div>
                    </div>

                    <div v-if="subtabIndex === 6" id="MapaMental" class="tabcontent"
                        :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                        <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                            <AiMindMap v-if="aiContent && aiContent.mindMap" :content="aiContent.mindMap" />
                            <StudentClassEmptyTabFallback v-else />
                        </div>
                    </div>

                    <div v-show="subtabIndex === 7" id="Quiz" class="tabcontent"
                        :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
                        <div class="tabcontent-text" :style="$theme.darkTheme ? '' : 'color: #333333 !important'">
                            <AiQuiz v-if="aiContent && aiContent.quiz" :quiz="aiContent.quiz" />
                            <StudentClassEmptyTabFallback v-else />
                        </div>
                    </div>
                    <!-- Content IA Interno -->
                </div>
            </div>
        </div>

        <!-- Box Next-Content -->
        <div class="box-next-content container" v-if="nextLearningObjects.length" v-show="!inFullscreen"
            :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'">
            <div class="nextContentTitle" :style="{ color: $theme.primaryColor }">
                Próximos conteúdos
            </div>
            <div class="nextCarousel">
                <Carousel :show-category="true" :items="nextLearningObjects" @click="navigateToClass"
                    :metadata="{ title: '' }" />
            </div>
        </div>
        <!-- <div class="hidden-dot">
      <a href="javascript:;" @click="enableChatBot()">.</a>
    </div> -->
    </div>
</template>

<script>
import Carousel from "@/components/Carousel";
import CommentsBox from "@/components/CommentsBox";
import StudentClassEmptyTabFallback from "@/components/LearningObject/ArtificialInteligence/EmptyTabFallback.vue";
import AiMindMap from "@/components/LearningObject/ArtificialInteligence/MindMap/index.vue";
import AiQuiz from "@/components/LearningObject/ArtificialInteligence/Quiz/index.vue";
import PdfViewer from "@/components/PdfViewer";
import CoremediaPlayer from "@/components/Player.vue";
import Quiz from "@/components/Quiz";
import SpinnerWave from "@/components/SpinnerWave";
import IconAI from "@/components/UI/IconAI";
import IconDownload from "@/components/UI/IconDownload";
import IconNext from "@/components/UI/IconNext";
import IconPrevious from "@/components/UI/IconPrevious";
import NavNextBt from "@/components/UI/NavNextBt";
import NavPrevBt from "@/components/UI/NavPrevBt";
import { formatTimestamp } from "@/filters/formatTimestamp";
import { AuthService } from "@/services/auth.service";
import M from "materialize-css";
import { mapGetters, mapState } from "vuex";

var userAgent = navigator.userAgent;
var platform = navigator.platform;
var maxTouchPoints = navigator.maxTouchPoints;
const isIE = /Trident/.test(userAgent);
const isIOS =
    /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) ||
    (platform === "MacIntel" && maxTouchPoints > 1);
const isIOSChrome = /CriOS/.test(userAgent);

const createObjectURL = (function createObjectURLClosure() {
    // Blob/createObjectURL is not available, falling back to data schema.
    const digits =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=";

    // eslint-disable-next-line no-shadow
    return function createObjectURL(data, contentType, forceDataSchema = false) {
        if (!forceDataSchema && URL.createObjectURL) {
            const blob = new Blob([data], { type: contentType });
            return URL.createObjectURL(blob);
        }

        let buffer = `data:${contentType};base64,`;
        for (let i = 0, ii = data.length; i < ii; i += 3) {
            const b1 = data[i] & 0xff;
            const b2 = data[i + 1] & 0xff;
            const b3 = data[i + 2] & 0xff;
            const d1 = b1 >> 2,
                d2 = ((b1 & 3) << 4) | (b2 >> 4);
            const d3 = i + 1 < ii ? ((b2 & 0xf) << 2) | (b3 >> 6) : 64;
            const d4 = i + 2 < ii ? b3 & 0x3f : 64;
            buffer += digits[d1] + digits[d2] + digits[d3] + digits[d4];
        }
        return buffer;
    };
})();

function timeSpanToMs(timeSpan) {
    if (!timeSpan || !/^(\d{1,2}:?){0,4}$/.test(timeSpan)) return 0;

    const [seconds = 0, minutes = 0, hours = 0, days = 0] = [
        ...timeSpan.split(":").map(Number),
    ].reverse();

    return (
        (days * 60 * 60 * 24 + hours * 60 * 60 + minutes * 60 + seconds) * 1000
    );
}

export default {
    name: "StudentClass",
    components: {
        StudentClassEmptyTabFallback,
        Carousel,
        CoremediaPlayer,
        CommentsBox,
        PdfViewer,
        Quiz,
        SpinnerWave,
        IconNext,
        IconPrevious,
        IconDownload,
        IconAI,
        NavPrevBt,
        NavNextBt,
        AiQuiz,
        AiMindMap,
    },
    data() {
        return {
            tabIndex: 1,
            subtabIndex: 4,
            loading: true,
            showReplyBoxId: 0,
            ratingsHover: null,
            transcriptionsVisible: false,
            devMute: process.env.VUE_APP_DEVMUTE == "true",
            previousPath: null,
            activeDownloads: [],

            searchQuery: "",
            searchTimeout: null,
        };
    },
    async mounted() {
        this.load();
        window.addEventListener("source-click", this.navigateToClassFromBubble);
    },
    beforeRouteLeave(to, from, next) {
        window.removeEventListener("source-click", this.navigateToClassFromBubble);
        next();
    },
    watch: {
        currentEnroll: {
            handler(newVal, oldVal) {
                if (newVal) {
                    this.enableChatBot();
                }
            },
        },
        "$route.path": function () {
            this.load();
        },
        "$route.query.time": function () { },
        "search.text": (text) => {
            this.searchQuery = text;
        },
        "$route.hash": {
            handler: function (timeHash) {
                this.seekToTime(timeHash);
            },
            immediate: true,
        },
    },
    computed: {
        ...mapState({
            inFullscreen: (state) => state.enroll.inFullscreen,
            config: (state) => state.config,
            currentEnroll: (state) => state.enroll.current,
            currentUser: (state) => state.user.currentUser,
            currentLearningObject: (state) => state.enroll.currentLearningObject,
            learningObjects: (state) => state.enroll.currentLearningObjects,
            currentLearningObjectComents: (state) => state.comment.comments,
            currentLearningObjectRatings: (state) => state.learningObject.ratings,
            drmConfig: (state) => state.learningObject.drm,
            sidenavIsOpen: (state) => state.config.sidenavIsOpen,
            aiContent: (state) => state.learningObject.aiContent,
            learningObjectsEnrolls: (state) =>
                state.shopWindow.learningObjectsEnrolls,
        }),

        ...mapGetters({
            isDesktop: "dom/isDesktop",
        }),
        checkCurrentLearningObjectState() {
            if (!this.currentLearningObject) return false;
            if (this.currentLearningObject.state == "Unpublished") return false;
            if (
                this.currentLearningObject.state == "Scheduled" &&
                this.currentLearningObject.releasedDate
            )
                return false;
            return true;
        },
        nextLearningObjects() {
            if (!this.currentLearningObject) return {};

            const structureType = this.currentEnroll.structureType.toLowerCase();

            const currentLearningObjectIndex = this.learningObjects
                .map((lo) => lo.id)
                .indexOf(this.currentLearningObject.id);

            var nextLearningObjects = [];

            for (
                var i = currentLearningObjectIndex + 1;
                i < this.learningObjects.length;
                i++
            ) {
                nextLearningObjects.push(this.learningObjects[i]);

                if (nextLearningObjects.length > 3) break;
            }

            return nextLearningObjects;
        },
        enableComments() {
            if (!this.currentEnroll) return false;
            return this.currentEnroll.settings.enableComments;
        },
    },
    methods: {
        getPDFUrl() {
            if (
                !this.drmConfig ||
                this.drmConfig.codCurso != this.currentEnroll.courseId
            )
                return "";

            localStorage.setItem(
                "desabilitarDownloadVisualizador",
                this.drmConfig.desabilitarDownloadVisualizador
            );
            localStorage.setItem(
                "desabilitarSelecaoVisualizador",
                this.drmConfig.desabilitarSelecaoVisualizador
            );

            if (!this.drmConfig.ativo) return this.currentLearningObject.pdf.url;

            localStorage.setItem("time", AuthService.getAccessToken());
            localStorage.setItem("applicationid", this.currentEnroll.applicationId);

            return this.getAuthPDFUrl();
        },

        getAuthPDFUrl() {
            return (
                process.env.VUE_APP_EAD_API_URL +
                "/courses/" +
                this.currentEnroll.courseId +
                "/learning-obj/" +
                this.currentLearningObject.id +
                "/pdf"
            );
        },

        getAuthFilePDFUrl(fileId) {
            return (
                process.env.VUE_APP_EAD_API_URL +
                "/courses/" +
                this.currentEnroll.courseId +
                "/file/" +
                fileId +
                "/pdf"
            );
        },

        changeColor(e) {
            e.target.style.color = this.$theme.primaryText;
            e.target.style.backgroundColor = this.$theme.primaryColor;
            // e.target.style.borderBottomColor = this.$theme.primaryColor;
            // e.target.style.borderBottomWidth = "1px";
        },
        changeColorToOriginal(e) {
            if (e.$event.target.classList.contains("active")) return;
            if (this.$theme.darkTheme) {
                e.$event.target.style.backgroundColor = "transparent";
                e.$event.target.style.color = "#f2f3f7";
            } else {
                e.$event.target.style.backgroundColor = "transparent";
                e.$event.target.style.color = "#333333";
            }

            // e.$event.target.style.borderBottomColor = this.$theme.primaryColor;
            // e.$event.target.style.borderBottomWidth = "1px";
        },

        setActive(value, e) {
            this.tabIndex = value;
            if (value != 3) {
                this.subtabIndex = '';
            }

            if (value == 3 && !this.subtabIndex) {
                this.subtabIndex = 5;
            }
            var tabs = document.querySelectorAll(".tablink");
            // for (var i = 0; i < tabs.length; i++) tabs[i].removeAttribute("style");
            e.$event.target.style.backgroundColor = this.$theme.primaryColor;
            e.$event.target.style.borderBottomColor = this.$theme.primaryColor;
            e.$event.target.style.borderBottomWidth = "1px";
            e.$event.target.style.color = this.$theme.primaryText;
        },

        subSetActive(subvalue, e) {
            this.subtabIndex = subvalue;
            var subTabs = document.querySelectorAll(".sub-tablink");
            e.$event.target.style.backgroundColor = this.$theme.primaryColor;
            e.$event.target.style.borderBottomColor = this.$theme.primaryColor;
            e.$event.target.style.borderBottomWidth = "1px";
            e.$event.target.style.color = this.$theme.primaryText;
        },

        async load() {
            this.loading = true;

            var lastLearningObject = null;

            if (this.currentLearningObject && this.currentLearningObject.broadcast) {
                lastLearningObject = {
                    id: this.currentLearningObject.id,
                    classId: this.currentLearningObject.classId,
                    disciplineId: this.currentLearningObject.disciplineId,
                    moduleId: this.currentLearningObject.moduleId,
                    broadcastId: this.currentLearningObject.broadcast.id,
                };
            }

            if (
                !this.currentEnroll ||
                this.currentEnroll.id != this.$route.params.enrollId
            ) {
                console.log("STUDENT CLASS", this.$route.params);
                await this.$store.dispatch("enroll/setCurrentEnroll", {
                    enrollId: this.$route.params.enrollId,
                });
            }

            await this.$store.dispatch(
                "enroll/setCurrentLearningObject",
                this.$route.params.id
            );

            this.$gtag.pageview({
                page_title:
                    this.currentEnroll.name + " - " + this.currentLearningObject.title,
                page_path:
                    "/p/course/" +
                    this.currentEnroll.courseId +
                    "/class/" +
                    this.currentLearningObject.id,
            });

            this.loading = false;

            if (this.isDesktop && this.sidenavIsOpen)
                this.$emit("openLeftMenu", this.currentLearningObject.classId);

            this.$store.dispatch("enroll/setLastAccess", {
                enroll: this.currentEnroll,
                learningObject: this.currentLearningObject,
            });

            if (
                this.currentLearningObject.pdf ||
                this.currentLearningObject.htmlContent
            ) {
                this.$store.dispatch("enroll/setProgress", {
                    enrollId: this.currentEnroll.id,
                    courseId: this.currentEnroll.courseId,
                    moduleId: this.currentLearningObject.moduleId,
                    disciplineId: this.currentLearningObject.disciplineId,
                    classId: this.currentLearningObject.classId,
                    learningObjectId: this.currentLearningObject.id,
                    broadcastId: this.currentLearningObject.broadcast
                        ? this.currentLearningObject.broadcast.id
                        : null,
                    progress: 100,
                });
            }

            this.$store.dispatch("comment/getComments", {
                CourseId: this.$store.state.enroll.current.courseId,
                LearningObjectId: this.$route.params.id,
            });

            this.$store.dispatch("learningObject/getRatings", {
                LearningObjectId: this.$route.params.id,
            });

            this.$store.dispatch("learningObject/getAI", {
                LearningObjectId: this.$route.params.id,
            });

            await this.$store.dispatch(
                "learningObject/getDRM",
                this.currentEnroll.courseId
            );

            if (lastLearningObject) {
                this.$store.dispatch("enroll/refreshProgress", {
                    enrollId: this.currentEnroll.id,
                    courseId: this.currentEnroll.courseId,
                    moduleId: lastLearningObject.moduleId,
                    disciplineId: lastLearningObject.disciplineId,
                    classId: lastLearningObject.classId,
                    learningObjectId: lastLearningObject.id,
                    broadcastId: lastLearningObject.broadcastId,
                });
                this.$store.dispatch("enroll/getCourseProgress", this.currentEnroll.id);
            }

            this.$nextTick(() => {
                var dropDowns = document.querySelectorAll(".dropdown-trigger");
                M.Dropdown.init(dropDowns, {
                    constrainWidth: false,
                    coverTrigger: false,
                    closeOnClick: false,
                });
            });
        },

        getCurrentClass() {
            const structureType = this.currentEnroll.structureType.toLowerCase();
            var currentClass;
            switch (structureType) {
                case "modulesanddisciplines":
                    //TODO: Nada no layout suporta essa organização
                    break;
                case "modules":
                case "disciplinas":
                    currentClass = this.currentEnroll[structureType].classes.find(
                        (c) => c.id == currentClassId
                    );
                    break;
                case "classes":
                    currentClass = this.currentEnroll.classes.find(
                        (c) => c.id == currentClassId
                    );
                    break;
            }
            return currentClass;
        },
        handleEnded() {
            if (this.config.autoNext) {
                this.navigateNextClass(true);
            }
        },
        coremediaReady(event) {
            this.transcriptionsVisible = event.HasSubtitles;
            //event.IsVideoJs: bool
            //event.IsVidemo: bool
        },
        navigatePreviousClass() {
            if (!this.currentLearningObject.previousId) return;

            this.$router.push({
                name: "class",
                params: {
                    enrollId: this.currentEnroll.id,
                    id: this.currentLearningObject.previousId,
                },
            });
        },
        navigateNextClass(auto) {
            if (!this.currentLearningObject.nextId) return;

            var routerParams = {
                name: "class",
                params: {
                    enrollId: this.currentEnroll.id,
                    id: this.currentLearningObject.nextId,
                },
            };
            if (auto) routerParams.query = { time: 0 }; //pq não funciona 0?

            this.$router.push(routerParams);
        },
        navigateToClass(eventData) {
            this.$router.push({
                name: "class",
                params: { enrollId: this.currentEnroll.id, id: eventData.id },
            });
        },
        async navigateToClassFromBubble(eventData) {
            try {
                let enrollId = null;
                const learningObjectId = +eventData.detail.source.custom_id;

                if (!this.currentEnroll.shopWindowId) {
                    if (
                        !this.currentLearningObjects?.some(
                            (learningObject) => learningObject.id === learningObjectId
                        )
                    )
                        throw new Error("Learning Object doesn't belong to this course");

                    enrollId = this.currentEnroll.id;
                } else {
                    if (!this.learningObjectsEnrolls.length)
                        await this.$store.dispatch(
                            "shopWindow/getLearningObjectsEnrolls",
                            this.$currentUser.studentId
                        );

                    var learningObjectsEnrolls = this.learningObjectsEnrolls;
                    const learningObject =
                        learningObjectsEnrolls &&
                        learningObjectsEnrolls.find(
                            (obj) => obj.learningObjectId === learningObjectId
                        );
                    if (learningObject) {
                        enrollId = learningObject.enrollId;
                    }
                }

                await this.$router.push({
                    name: "class",
                    params: {
                        enrollId: enrollId,
                        id: learningObjectId,
                    },
                });
                window.minimizeChatBot();
            } catch (error) {
                console.error(error);
                this.$store.commit("toast/NEW", {
                    type: "warn",
                    message: "Ops! Não foi possível navegar para esta aula.",
                });
            }
        },
        setLearningObjectRating(rating) {
            if (this.currentLearningObjectRatings.value != rating) {
                this.$store.dispatch("learningObject/setRatings", {
                    LearningObjectId: this.currentLearningObject.id,
                    Rating: rating,
                });
            }
        },
        async setProgress() {
            await this.$store.dispatch("enroll/setProgress", {
                enrollId: this.currentEnroll.id,
                courseId: this.currentEnroll.courseId,
                moduleId: this.currentLearningObject.moduleId,
                disciplineId: this.currentLearningObject.disciplineId,
                classId: this.currentLearningObject.classId,
                learningObjectId: this.currentLearningObject.id,
                broadcastId: this.currentLearningObject.broadcast
                    ? this.currentLearningObject.broadcast.id
                    : null,
                progress: this.currentLearningObject.progress < 100 ? 100 : 0,
            });
            this.$store.dispatch("enroll/getCourseProgress", this.currentEnroll.id);
        },
        async downloadFile(file, i, currentLearningObject, el) {
            if (file.generatedItemLink || file.loadingItem) return;

            this.$store.dispatch("enroll/updateLoadingFile", {
                i: i,
                value: true,
            });

            this.$nextTick(async () => {
                var options = {
                    url: file.url,
                    name: file.name,
                    filename: file.name + "." + file.fileType,
                    size: file.size,
                };

                if (file.url.indexOf(".pdf") > -1 && this.drmConfig.ativo) {
                    options.headers = {
                        authorization: "bearer " + AuthService.getAccessToken(),
                        "x-applicationid": this.currentEnroll.applicationId,
                    };

                    options.url =
                        process.env.VUE_APP_EAD_API_URL +
                        "/courses/" +
                        this.currentEnroll.courseId +
                        "/file/" +
                        file.id +
                        "/pdf";
                }

                if (file.url.indexOf(".pdf") > -1 && this.drmConfig.abrirNavegador) {
                    if (this.drmConfig.ativo) {
                        options.url = await this.$store.dispatch(
                            "learningObject/getDMRAuth",
                            {
                                courseId: this.currentEnroll.courseId,
                                fileId: file.id,
                            }
                        );
                    }

                    this.$store.dispatch("enroll/updateLoadingFile", {
                        i: i,
                        value: false,
                    });

                    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
                        document.location = options.url;
                        return;
                    }

                    var instance = M.Dropdown.getInstance(document.getElementById("ddx"));
                    instance.close();
                    window.open(options.url, "_blank").focus();
                    return;
                }

                /*
                if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
        
                  this.$store.dispatch("enroll/updateLoadingFile", {
                    i: i,
                    value: false,
                  });
        
                  if(this.drmConfig.ativo && file.url.indexOf('.pdf') > -1 )
                  {
                    this.loading = true;
        
                    options.url = await this.$store.dispatch("learningObject/getDMRAuth", {
                      courseId:this.currentEnroll.courseId,
                      fileId:file.id
                    });
        
                    this.loading = false;
                  }
        
                  // document.location = options.url;
        
                  // var windowReference = window.open();
                  // windowReference.location = options.url;
        
                  // window.open(options.url, '_blank').focus();
                  // return;
                }
                // */

                var downloader = new Downloader();
                this.activeDownloads.push({ fileName: file.name, progress: 0 });
                downloader.onprogress = this.downloadProgress;
                downloader.onfinished = (filename) => {
                    this.$store.dispatch("enroll/updateLoadingFile", {
                        i: i,
                        value: false,
                    });
                    this.downloadFinished(filename);
                };

                if (options.url.indexOf(".pdf") > -1 && this.drmConfig.ativo) {
                    options.headers = {
                        authorization: "bearer " + AuthService.getAccessToken(),
                        "x-applicationid": this.currentEnroll.applicationId,
                    };

                    var fileId = fileUrl.split("/").pop().split(".")[0];
                    options.url = this.getAuthFilePDFUrl(fileId);
                }

                downloader.download(options);
            });
        },

        openTab(url) {
            // Create link in memory
            var a = window.document.createElement("a");
            a.target = "_blank";
            a.href = url;

            // Dispatch fake click
            var e = window.document.createEvent("MouseEvents");
            e.initMouseEvent(
                "click",
                true,
                true,
                window,
                0,
                0,
                0,
                0,
                0,
                false,
                false,
                false,
                false,
                0,
                null
            );
            a.dispatchEvent(e);
        },

        downloadProgress(evt) {
            for (var i = 0; i < this.activeDownloads.length; i++) {
                if (this.activeDownloads[i].fileName == evt.filename) {
                    this.activeDownloads[i].progress = evt.progress;
                    break;
                }
            }
        },
        isDownloading() {
            return this.activeDownloads.length > 0;
        },
        progressPercent(file) {
            if (!file) return;
            const activeDownload = this.activeDownloads.filter((ad) => {
                return ad.fileName == file.name;
            })[0];
            if (activeDownload) return activeDownload.progress + "%";
            return;
        },
        downloadFinished(filename) {
            for (var i = 0; i < this.activeDownloads.length; i++) {
                if (this.activeDownloads[i].fileName == filename) {
                    this.activeDownloads.splice(i, 1);
                    break;
                }
            }
        },

        debounceSearch(value, updateHandler) {
            clearTimeout(this.searchTimeout);
            this.searchQuery = value;

            this.searchTimeout = setTimeout(() => {
                updateHandler(this.searchQuery);
            }, 300);
        },
        handleTranscriptSelectedChange(index) {
            var lis = this.$refs.transcripts.querySelectorAll("li");

            this.$refs.transcripts.scrollTop =
                lis[index].offsetTop -
                this.$refs.transcripts.offsetTop -
                this.$refs.transcripts.clientHeight / 1.8;
        },
        handleSearchResultChanged(indexes) {
            var lis = this.$refs.transcripts.querySelectorAll("li");

            if (!indexes) return;

            var index = indexes.phraseIndex;
            this.$refs.transcripts.scrollTop =
                lis[index].offsetTop -
                this.$refs.transcripts.offsetTop -
                this.$refs.transcripts.clientHeight / 1.8;
        },
        getRatingsClass(i) {
            if (this.ratingsHover) {
                if (i <= this.ratingsHover) return "fa fa-star";
                else return "fa fa-star-o";
            } else {
                if (i <= this.currentLearningObjectRatings.value) return "fa fa-star";
                else return "fa fa-star-o";
            }
        },
        isCompressed() {
            return this.sidenavIsOpen;
        },
        playAndSeek(begin, seek) {
            if (begin) {
                try {
                    if (!window.vdplayer)
                        return console.log("No VDPlayer found. Cannot seek");
                    vdplayer.play();
                    vdplayer.seekTo(begin);
                } catch (error) {
                    console.log(error);
                }
            }
        },
        seekToTime(timeHash) {
            if (timeHash) {
                try {
                    if (!window.vdplayer)
                        return console.log("No VDPlayer found. Cannot seek");

                    const time = timeHash.replace("#", "");
                    const seekTime = timeSpanToMs(time) / 1000;
                    vdplayer.play();
                    vdplayer.seekTo(seekTime);
                } catch (error) {
                } finally {
                    this.$router.replace({ hash: "" });
                }
            }
        },
        async enableChatBot() {
            if (!this.currentEnroll?.agentId) return;
            var agentImage = this.currentEnroll.agentImage;
            if (agentImage && !agentImage.startsWith("http")) {
                agentImage = `${process.env.VUE_APP_CDN_URL}` + agentImage;
            }
            await window.enableChatBot(
                this.currentEnroll.agentId,
                agentImage,
                this.currentEnroll.id,
                this.$currentUser,
                null
            );
        },
    },
    filters: {
        bytes: function (bytes) {
            if (bytes > 1048576) {
                return (bytes / 1024 / 1024).toFixed(1) + "MB";
            } else {
                return (bytes / 1024).toFixed(1) + "KB";
            }
        },
        formatTimestamp,
    },
};
</script>
<style scoped lang="scss">
.aiTitle {
    padding-top: 25px;
    font-weight: bold;
    border-bottom: 1px solid var(--primary-color);
}

.aiContent {
    word-wrap: break-word;
    padding: 20px;
    white-space: break-spaces;
}

.btn-info {
    width: 100%;
    height: 35px;
}

@media only screen and (min-width: 993px) {
    .player {
        position: relative;
        width: 70%;
        margin: 0 auto;
        max-width: 148vh;
        border-radius: 5px;
        overflow: hidden;
    }
}

.rating-love i {
    margin-right: 4px;
    font-size: 20px;
}

.menu-previous.disabled,
.menu-next.disabled {
    color: #666;
}

.dropdown-content li {
    min-height: auto;
    border-top: solid 1px #333;
}

.dropdown-content,
.dropdown-content li>a,
.dropdown-content li>span {
    background: #222;
    border-radius: 5px;
    font-family: Roboto;
    font-size: 12px;
}

.pdf-navigation-goto {
    font-family: Montserrat;
    font-size: 13px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #bfbfbf;
}

.pdf-nav-previous,
.pdf-nav-next {
    background: none;
}

.box-info .tabcontent-text {
    word-break: break-word;
}

.transcripts {
    height: 300px;
    overflow-y: auto;
    padding-right: 1em;

    @media screen and (max-width: 640px) {
        padding-left: 0.5em !important;
    }
}

.transcripts li.segment {
    list-style: none !important;
    display: flex;
    align-items: baseline;
    padding: 8px 16px;
    border-radius: 4px;
    transition: background ease-in-out 50ms;
}

.transcripts li:hover {
    cursor: pointer;
}

.transcripts li {

    &.current,
    &:hover {
        background: rgb(255, 255, 255, 0.2);

        .segment-text {
            font-weight: 600;
        }

        .segment-timestamp {
            opacity: 1;
        }
    }
}

.transcripts.light li {

    &.current,
    &:hover {
        background: rgb(0, 0, 0, 0.05);

        .segment-text {
            font-weight: 500;
        }
    }
}

.transcripts li .filtered {
    background: #bbbbbb;
    color: #000;
}

.transcripts.light li .filtered {
    background: #bbbbbb;
    color: #000000;
}

.transcripts li .filtered.highlight {
    background: #0564b1;
    color: #fff;
    font-weight: 500;
}

.transcripts li .segment-timestamp {
    color: var(--primary-text);
    background-color: var(--primary-color);

    flex-basis: 44px;
    flex-shrink: 0;
    padding: 0 4px;
    margin-right: 16px;
    border-radius: 4px;
    opacity: 0.8;
    white-space: nowrap;
    text-align: center;
    user-select: none;
}

.download-progress {
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 0;
    background: rgba(255, 255, 255, 0.1);
    transition: width 0.5s;
}

@media screen and (max-width: 680px) {
    .transcripts li.segment {
        padding-inline: 8px;

        .segment-timestamp {
            margin-right: 12px;
        }
    }
}

@media only screen and (min-width: 993px) {
    .box-menu-navigation {
        display: none !important;
    }
}
</style>
