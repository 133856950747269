<template>
    <!-- background: url(../assets/images/bg.png); -->
    <div id="app" :class="{ mobilev: isMobile, deskv: !isMobile }"
        :style="$theme.darkTheme ? '' : 'background: #f2f3f7'">
        <UiToastList />
        <ComposedHeader ref="header" v-if="hasHeader" :layout-theme="currentLayoutTheme"></ComposedHeader>
        <router-view @openLeftMenu="headerOpenMenu($event)"></router-view>
        <ModalMyGroup :layout-theme="currentLayoutTheme"></ModalMyGroup>
        <ModalMyCertificate :layout-theme="currentLayoutTheme"></ModalMyCertificate>
        <ModalProfile :layout-theme="currentLayoutTheme"></ModalProfile>
        <ModalConfiguration :layout-theme="currentLayoutTheme"></ModalConfiguration>
        <ModalNotes :layout-theme="currentLayoutTheme"></ModalNotes>
        <ModalSupport :layout-theme="currentLayoutTheme"></ModalSupport>
        <ModalCourseFiles :layout-theme="currentLayoutTheme"></ModalCourseFiles>
        <Footer v-if="hasFooter" :layout-theme="currentLayoutTheme"></Footer>
        <script type="module">
      import Chatbox from "https://cdn.pagtrust.ai/dist/chatbox/index.js";

      window.enableChatBot = async function (
        agentId,
        agentImage,
        enrollId,
        currentUser,
        shopWindowId
      ) {
        if (window.widget) return;

        const [firstName, lastName] = currentUser.name ? currentUser.name.split(' ') : ['', ''];
        const phoneNumber = currentUser.phones[0]?.area && currentUser.phones[0]?.number 
    ? (currentUser.phones[0]?.ddi ?? '55') + (currentUser.phones[0]?.area ?? '') + (currentUser.phones[0]?.number ?? '')
    : '';

        window.widget = await Chatbox.initBubble({
          agentId: agentId,
          enrollId: enrollId,
          userMktId: currentUser.studentId,
          shopWindowId: shopWindowId,
          contact: {
            email: currentUser.email ||'',
            firstName: firstName ?? '',
            lastName: lastName ?? '',
            phoneNumber: phoneNumber ?? '',
          },
          containerClassName: "coremedia-ignore",
          interface: {
            iconUrl: agentImage || "https://cdn.pagtrust.ai/icon.png",
            initialMessages: ["Olá, como posso lhe ajudar?"],
            position: "right",
            bubbleIconStyle: {
              padding: "5px",
            },
          },
        });
      };

      window.minimizeChatBot = async function () {
        window.widget.open();
        setTimeout(() => {
          window.widget.close();
        });
      };
    </script>
    </div>
</template>

<script>
import ComposedHeader from "./ComposedHeader.vue";
import Footer from "./Footer.vue";
import UiToastList from "@/components/UI/UiToastList";
import ModalProfile from "@/pages/modals/Profile";
import ModalConfiguration from "@/pages/modals/Configuration";
import ModalNotes from "@/pages/modals/Notes";
import ModalSupport from "@/pages/modals/Support";
import ModalMyGroup from "@/pages/modals/MyGroup";
import ModalMyCertificate from "@/pages/modals/MyCertificate";
import ModalCourseFiles from "@/pages/modals/CourseFiles";

import { mapState } from "vuex";

export default {
    name: "AppLayout",
    components: {
        ComposedHeader,
        Footer,
        ModalProfile,
        ModalConfiguration,
        ModalNotes,
        ModalMyGroup,
        ModalMyCertificate,
        ModalSupport,
        ModalCourseFiles,
        UiToastList,
    },
    data() {
        return {};
    },
    computed: {
        ...mapState({
            currentLayoutTheme: (state) => state.user.currentUser.layoutTheme,
            selectedEnroll: (state) => state.enroll.current,
        }),
        hasHeader() {
            const routeMetaHeader = this.$route.meta.header;

            switch (routeMetaHeader) {
                case "no-header":
                    return false;
                case "dark":
                    this.$store.dispatch("user/setCurrentTheme", "dark");
                    return true;
                case "light":
                    this.$store.dispatch("user/setCurrentTheme", "light");
                    return true;
            }
        },
        hasFooter() {
            const routeMetaFooter = this.$route.meta.footer;

            switch (routeMetaFooter) {
                case "no-footer":
                    return false;
                case "dark":
                    this.$store.dispatch("user/setCurrentTheme", "dark");
                    return true;
                case "light":
                    this.$store.dispatch("user/setCurrentTheme", "light");
                    return true;
            }
        },
        headerMeta() {
            const headerMetaParams = this.$route.meta.headerMeta;
            if (headerMetaParams) return headerMetaParams;
            else return {};
        },
    },
    methods: {
        headerOpenMenu(classId) {
            this.$refs.header.openLeftMenu(classId);
        },
    },
};
</script>
