<template>
    <svg id="mind-map" class="flex-1" ref="markmap" :class="{dark : $theme.darkTheme}"></svg>
</template>

<script>
import { Transformer } from "markmap-lib";
import { Markmap } from "markmap-view";
import { deriveOptions } from 'markmap-view';


const transformer = new Transformer();

export default {
    name: "AiMindMap",
    props: {
        isActive: Boolean,
        content: { type: String, default: "" },
    },
    watch: {
        content(content, oldContent) {
            if (!content && oldContent) return this.destroyMindMap();
            this.setMindMap();
        },
    },
    mounted() {
        this.setMindMap();
    },
    methods: {
        setMindMap() {
            const mindMapContent = this.content;

            if (!mindMapContent) return;
            if (this.mindMapInstance) this.mindMapInstance.destroy();

            const svgEl = this.$refs.markmap;
            if (!svgEl) return;
            
            var primaryColor = this.$theme.primaryColor;
            
            const jsonOptions = {
                color: [primaryColor],
            };

            const markmapOptions = deriveOptions(jsonOptions);

            this.mindMapInstance = Markmap.create(svgEl, markmapOptions);

            const { root } = transformer.transform(mindMapContent);
            this.mindMapInstance.setData(root);
            this.fitMindMapIntoView();
        },
        destroyMindMap() {
            if (this.mindMapInstance) this.mindMapInstance.destroy();
        },
        fitMindMapIntoView() {
            if (!this.content) return;
            this.$nextTick(() => this.mindMapInstance.fit());
        },
    },
};
</script>

<style scoped>
#mind-map {
    display: block;
    width: 100%;
    height: 50vh;

}

</style>
