<template>
  <LoginTemplate
    :email="email"
    :password="password"
    :isExternalUrl="isCourseUrl"
    :submitting="loading"
    :submittingPasswordResetLink="sendingResetPasswordLink"
    showPlatformLogo
    ref="loginTemplate"
    @input="handleInput"
    @forgotPassword="forgotPasswordRequest"
    @submit="makeLogin"
  />
</template>

<script>
import LoginTemplate from "@/components/Login.vue";
import AppLogo from "@/components/UI/AppLogo.vue";
import UiToastList from "@/components/UI/UiToastList";
import { AuthService } from "@/services/auth.service";
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: process.env.VUE_APP_DEV_USER,
      password: process.env.VUE_APP_DEV_PASS,

      isCourseUrl:
        window.location.href.indexOf(process.env.VUE_APP_EAD_URL) === -1,
      showPass: false,
      error: "",
      loading: false,
      enableResetPassworLink: true,
      redirectToCourse: null,
      sendingResetPasswordLink: false,
      logoVisible: false
    };
  },

  components: {
    LoginTemplate,
    AppLogo,
    UiToastList
  },

  mounted() {
    this.$gtag.pageview({
      page_title: "login",
      page_path: "/"
    });

    this.loadCustomBackgrounds();
  },

  computed: {
    ...mapState({
      enrolls: state => state.enroll.enrolls
    })
  },

  methods: {
    handleInput({ target, value }) {
      if (this[target] === undefined) return;
      this[target] = value;
    },
    async makeLogin() {
      if (this.loading) return;

      if (this.email == "" || this.password == "") {
        this.$store.commit("toast/NEW", {
          message: "E-mail e senha obrigatórios"
        });
        return;
      }

      try {
        this.loading = true;
        await AuthService.makeLogin({
          email: this.email,
          password: this.password
        });
        this.error = "";
        await this.$store.dispatch("user/getCurrent");
        await this.$store.dispatch("notification/getNotifications");
        await this.$store.dispatch("config/getConfig");
        await this.$store.dispatch("user/updateProfilePic");
        await this.$store.dispatch("notification/initLoopTimeout");
        await this.$store.dispatch("enroll/getUserEnroll");

        var shopWindows;
        try {
          shopWindows = await this.$store.dispatch("shopWindow/getAllEnabled");
        } catch (error) {}

        await this.$store.dispatch("shopWindow/clearCurrent");
        if (
          this.enrolls &&
          this.enrolls.length == 1 &&
          this.enrolls[0].shopWindowId &&
          shopWindows
        ) {
          var shopWindow = shopWindows.find(e => {
            return e.id == this.enrolls[0].shopWindowId;
          });
          if (!shopWindow) {
            this.$router.push("/p/my-courses");
            return;
          }

          await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          this.$router.push("/p/trustflix");
          return;
        } else {
          if (this.isCourseUrl) {
            const accessToken = AuthService.getAccessToken();

            this.$refs.loginTemplate.sendMessageToPlatform({
              isExternalLogin: this.isCourseUrl,
              accessToken: accessToken,
              messageType: "course-login"
            });

            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls?.find(e => {
              return e.courseId == courseId;
            });
            if (!enroll || !enroll.id) {
              this.$store.commit("toast/NEW", {
                type: "error",
                message: "Você não tem uma matrícula válida para esse curso.",
                page: "login"
              });
              this.loading = false;
              return;
            }
            window.location = `${process.env.VUE_APP_EAD_URL}p/course/${enroll.id}`;
          } else {
            const enrollCount = this.enrolls ? this.enrolls.length : 0;
            if (!enrollCount) {
              this.$store.commit("toast/NEW", {
                type: "error",
                message: "Você não tem uma matrícula ativa.",
                page: "login"
              });
              this.loading = false;
              return;
            }
            this.$router.push("/p/my-courses");
          }
        }
      } catch (error) {
        if ((error && error.status === 401) || error.status === 404) {
          this.error = "Usuário e/ou senha não encontrado";
          this.$store.commit("toast/NEW", { message: this.error });
          this.loading = false;
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message,
          page: "login"
        });
        this.loading = false;
      }
    },

    async forgotPasswordRequest() {
      if (!this.enableResetPassworLink) return;

      if (!this.email?.trim()) {
        this.$store.commit("toast/NEW", { message: "Informe seu e-mail" });
        return;
      }

      if (!this.validateEmail(this.email)) {
        this.$store.commit("toast/NEW", {
          message: "Informe seu email corretamente."
        });
        return;
      }

      this.sendingResetPasswordLink = true;

      try {
        var forgotPwdPayload = { email: this.email, sendEmail: true };

        if (this.redirectToCourse) forgotPwdPayload.url = window.location.host;

        await AuthService.forgotPasswordRequest(forgotPwdPayload);
        this.$store.commit("toast/NEW", {
          message:
            "Um e-mail foi enviado com o link para cadastrar uma nova senha"
        });

        this.enableResetPassworLink = false;

        setTimeout(
          function() {
            this.enableResetPassworLink = true;
          }.bind(this),
          20000
        );
      } catch (error) {
        if ((error && error.status === 401) || error.status === 404) {
          this.error = "Usuário não encontrado";
          this.$store.commit("toast/NEW", { message: this.error });
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message,
          page: "login"
        });
      }

      this.sendingResetPasswordLink = false;
    },

    async loadCustomBackgrounds() {
      if (!this.isCourseUrl) {
        this.logoVisible = true;
        return;
      }

      var resp = await AuthService.getAppIdCourseId();
      this.redirectToCourse = resp.data;

      if (this.isCourseUrl || res.data.shopWindow)
        return this.$router.push({ name: "dark-login" });

      if (this.isCourseUrl) {
        var bgPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/backgrounds/${resp.data.courseId}.jpg`;
        var logoPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/courses/${resp.data.courseId}.png`;

        if (this.imageExists(bgPath)) {
          this.logoVisible = false;
          this.$refs.boxLogin.style = `background-image: url(${bgPath}), url("../assets/images/course-bg-overlay.png"); background-position: center center; background-color: rgba(0, 0, 0, 0.2); background-blend-mode: overlay;`;
        } else {
          this.logoVisible = true;
        }
      } else {
        this.logoVisible = true;
      }
    },

    defaultImg(event) {
      event.target.src = this.$appLogoSrc;
    },

    imageExists(image_url) {
      var http = new XMLHttpRequest();

      http.open("HEAD", image_url, false);
      http.send();

      return http.status == 200 || http.status == 304;
    },

    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    }
  }
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
.preloader-wrapper {
  margin-top: 7px;
}

.spinner-layer {
  border-color: #eee !important;
}

.forgot {
  border-color: #0564b1 !important;
}
</style>
