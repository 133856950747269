<template>
  <header
    :selectedEnroll="selectedEnroll"
    :class="{'navbar-fixed': this.$store.state.config.fixedMenu}"
  >
    <nav
      class="main-menu"
      :style="(!selectedEnroll && !$theme.darkTheme  ? 'background: #ffffff' : { background: $theme.secondaryColor })"
    >
      <div class="box-menu-top" v-if="!loading">
        <div v-show="!selectedEnroll">
          <div
            class="nav-wrapper menu-align"
            :style="(!selectedEnroll ? 'background: #ffffff' : { background: $theme.secondaryColor })"
          >
            <!-- <img
              v-show="this.$route.name == 'my-courses'"
              :src="$appLogoSrc"
              class="valign-wrapper img-logo-header"
              alt
              srcset
            /> -->
          </div>
        </div>
        <div
          v-show="selectedEnroll && selectedEnroll.id"
          :class="{
            'nav-wrapper': true,
            'menu-align': true,

          }"
          :style="{background: $theme.secondaryColor}"
        >
           <a
            href="javascript:;"
            :data-target="isDesktop ? '' : 'sidenav-1'"
            @click="toggleSideMenu"
            :class="{
              'menu-align mr1': true,
              'sidenav-trigger': !isDesktop
            }">
            <!-- <img src="@/assets/icons/menu_branco.svg" alt /> -->
            <IconMenu />
          </a>
          <img
            v-if="courseLogoVisible"
            :src="(selectedEnroll || {}).logo"
            class="valign-wrapper img-logo-header"
            :style="{ visibility: !loading && (selectedEnroll || {}).logo ? 'visible' : 'hidden' }"
            alt
            srcset
            @error="courseLogoVisible = false"
            @click="navToCourse()"
          />
          <a
          v-else
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="navToCourse()"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <IconHome />
          </i>
        </a>

        </div>
        <!-- <div
          class="pesquisa search-header"
          v-show="selectedEnroll && !isMobileDevice"
          :style="($theme.darkTheme ? 'background: #131318; color: #fff' : 'background: #efefef; color: #ff6600')"
        >
          <div
            class="input-field search-header-div"
            :style="($theme.darkTheme ? '' : 'color: #333333')"
          >
            <Autocomplete
              :items="autocompleteSearchData"
              item-search-property="title"
              item-label-property="title"
              :limit="15"
              :style="($theme.darkTheme ? 'color: #333333' : 'color: #333333')"
              @click="navigateToClass({ learningObjectId: $event.id})"
            ></Autocomplete>
          </div>
        </div> -->
        <div id="menu-right-group">
          <div class="viewClassroom" v-if="isPreview && selectedEnroll.classroomsList.length > 1">
            <!-- Mostrar como turma: -->
              <select  name="selectClassroom" v-model="selectedClassroom" @change="selectClassroom($event)" class="browser-default selectClassroom">
                <option value="" disabled selected>Mostrar como turma</option>
                <option value="allClassroom">Mostrar todo o conteúdo</option>
                <option
                  v-for="classroom in selectedEnroll.classroomsList"
                  :key="classroom.id"
                  :value=classroom.id
                  >
                  <span>{{ classroom.name }} <span v-if="classroom.default" >(Padrão)</span></span>
                </option>
              </select>
          </div>

          <GlobalSearchBar
            v-if="!isMobile && selectedEnroll"
            :items="autocompleteSearchData"
            filterPropName="title"
            type="learningObjects"
            @click="navigateToClass({ learningObjectId: $event.id })"
          />
          <GlobalSearchBar
            v-else-if="!isMobile && !selectedEnroll"
            :items="enrolls"
            filterPropName="name"
            type="courses"
            @click="navigateToCourse({ courseId: $event.id })"
          />

          <a
            href="javascript:;"
            data-target="sidenav-2"
            class="sidenav-trigger"
            @click="readNotifications()"
          >
            <div
              class="notify-count"
              v-show="unreadNotifications.length > 0"
            >{{unreadNotifications.length}}</div>
            <i
              :class="{'material-icons':true}"
              :style=" (selectedEnroll ? ($theme.darkTheme ? {color: $theme.secondaryText} : {color: $theme.secondaryText}) : ('color: #000'))"
            >notifications_none</i>
          </a>

          <a href="javascript:;" data-target="sidenav-3" id="sidenav3" class="sidenav-trigger">
            <div class="menu-right-icon" :style="{ background: shopwindowPrimaryColor }">
              <img
                v-if="userLogoVisible"
                :src="$profilePic | imageHandler"
                class="menu-user-img"
                alt
                @error="userLogoVisible = false"
              />
              <template v-else>{{$firstLetter}}</template>
            </div>
          </a>
        </div>
      </div>
    </nav>

    <!-- LEFT SIDEBAR	 -->
    <!-- não usar v-if -> não abre o menu -->
    <ul
      v-show="selectedEnroll"
      id="sidenav-1"
      :class="{ 'sidenav':true, 'sidenav-left': true, 'sidenav-expanded': sidenavIsOpen }"
      :style="($theme.darkTheme ? 'background: #0d0d13' : 'background: #F2F3F7')"
    >
      <li v-show="!isDesktop">
        <div class="menu-left-header" :style="{ background : $theme.secondaryColor}">
          <!-- <a href="javascript:;" class="left">
            <img
              :src="selectedEnroll.logo"
              class="valign-wrapper img-logo-header"
              alt
              srcset
              @error="$event.target.src = require('@/assets/images/default-course-image.png')"
              v-if="!loading && selectedEnroll"
              @click="navToCourse()"
            />
          </a> -->

          <a href="javascript:;" data-target="slide-out" class="sidenav-close right large" @click=" toggleSideMenu">
            <i class="material-icons btn-close" :style="({color: $theme.secondaryText})">close</i>
          </a>
        </div>
      </li>

      <li v-if="selectedEnroll && selectedEnroll.shopWindowId" :style="($theme.darkTheme ? 'background: #0d0d13' : 'background: #F2F3F7')">
        <a class="back-to-trustflix-bt" href="javascript:;" @click="navToCourse()">
            <div class="container valign-wrapper">
              <i class="material-icons" :style="({color: $theme.primaryColor})">arrow_back</i>
              <span :style="($theme.darkTheme ? 'color: white' : 'color: #333333')">
                Ir para Home
              </span>
          </div>
        </a>
      </li>

      <!--TODO: NÃO TEM LAYOUT PRONTO-->
      <li
      v-if="selectedEnroll && selectedEnroll.summary"
      :style="($theme.darkTheme ? 'background: #0d0d13' : 'background: #F2F3F7')"
      >
      <div class="details">
        <div :style="({color: $theme.primaryColor})" class="menuTitle" >Meu progresso</div>
          <div class="row progress-box">

              <ul class="course-summary">
                <li v-if="selectedEnroll.summary.modulesAmount > 0">
                  <div class="itemSummary">
                      <div class="itemDetail">
                        <IconModules class="iconSummary" />
                        <span
                        class="text"
                        style="opacity: 1;"
                        :style="($theme.darkTheme ? '' : 'color: #333333')"
                        >{{selectedEnroll.summary.modulesAmount > 1 ? ' Módulos' : ' Módulo'}}</span>
                      </div>

                       <span
                       class="number"
                       :style="($theme.darkTheme ? '' : 'color: #333333')"
                       >{{selectedEnroll.summary.modulesAmount}}/{{selectedEnroll.summary.modulesAmount}}
                      </span>
                  </div>
                </li>

                <li v-if="selectedEnroll.summary.disciplinesAmount > 0">
                  <div class="itemSummary">
                    <div class="itemDetail">
                  <IconModules class="iconSummary" />
                  <span
                  class="text"
                  style="opacity: 1;"
                  :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >{{selectedEnroll.summary.disciplinesAmount > 1 ? ' Disciplinas' : ' Disciplina'}}</span>
                </div>
                <span class="number">{{selectedEnroll.summary.disciplinesAmount}}/{{selectedEnroll.summary.disciplinesAmount}}</span>
              </div>
                </li>

                <li v-if="selectedEnroll.summary.classesAmount > 0">
                  <div class="itemSummary">
                    <div class="itemDetail">
                      <IconModules class="iconSummary" />
                      <span
                        class="text"
                        style="opacity: 1;"
                        :style="($theme.darkTheme ? '' : 'color: #333333')"
                      >{{selectedEnroll.summary.classesAmount > 1 ? ' Módulos' : ' Módulo'}}</span>
                    </div>
                    <span
                      class="number"
                      :style="($theme.darkTheme ? '' : 'color: #333333')"
                    >{{progressModuleDiscipline(selectedEnroll)}}</span>

                  </div>
                </li>

                <li v-if="selectedEnroll.summary.quizAmount > 0">


                  <div class="itemSummary">
                    <div class="itemDetail">
                    <IconClasses class="iconSummary" />
                    <span
                      class="text"
                      style="opacity: 1;"
                      :style="($theme.darkTheme ? '' : 'color: #333333')"
                    >{{selectedEnroll.summary.quizAmount > 1 ? ' Quizes' : ' Quiz'}}</span>

                  </div>
                  <span
                    class="number"
                    :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >{{selectedEnroll.summary.quizAmount}}/{{selectedEnroll.summary.quizAmount}}</span>

                  </div>

                </li>

                <li v-if="selectedEnroll.totalLearningObjects > 0"
                >


                <div class="itemSummary">
                  <div class="itemDetail">
                    <IconClasses class="iconSummary" />
                    <span
                    class="text"
                    style="opacity: 1;"
                    :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >{{(selectedEnroll.totalLearningObjects) > 1 ? ' Aulas' : ' Aula'}}</span>
                  </div>
                  <span
                    class="number"
                    :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >{{progressLObj(selectedEnroll)}}</span>
                  </div>


                </li>

                <li v-if="selectedEnroll.summary.filesAmount > 0">
                  <div
                  class="itemSummary download"
                  @click="$modal.show('coursefiles')"
                  >
                    <div class="itemDetail">
                      <IconMenuDownload class="iconSummary"  />
                      <span
                    class="text"
                    style="opacity: 1;"
                    :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >
                    {{selectedEnroll.summary.filesAmount > 1 ? ' Downloads' : ' Download'}}
                    <a
                      href="javascript:;"

                    >

                    </a>
                  </span>
                    </div>
                    <span
                      class="number"
                      :style="($theme.darkTheme ? '' : 'color: #333333')"
                    >{{selectedEnroll.summary.filesAmount}}</span>

                  </div>


                </li>
              </ul>

            <div class="course-progress">
                <svg height="96" width="96" style="transform: rotate(-90deg);">
                  <defs>
                    <linearGradient id="gradient" >
                      <stop offset="0%" :stop-color="$theme.primaryColor" />
                      <stop offset="100%" :stop-color="$theme.primaryColor" />
                    </linearGradient>
                  </defs>
                  <circle
                    cx="48"
                    cy="48"
                    r="40"
                    stroke="#363636"
                    stroke-width="2"
                    stroke-linecap="round"
                    fill="#0d0d13"
                    style="stroke-dasharray: 251; stroke-dashoffset: 0;"
                  />
                  <circle
                    cx="48"
                    cy="48"
                    r="40"
                    stroke="url(#gradient)"
                    stroke-width="6.5"
                    stroke-linecap="round"

                    :fill="($theme.darkTheme ? '#0d0d13' : '#F2F3F7')"
                    :style="{
                      'transform': 'rotate(-90)',
                      'transition': 'stroke-dashoffset 1s ease-in-out',
                      'stroke-dasharray': 251,
                      'stroke-dashoffset': courseProgress,
                      'box-shadow': '0 5px 10px 0 rgba(0, 0, 0, 0.4)',
                      }"

                  />
                  <text
                    x="50%"
                    y="52%"
                    text-anchor="middle"
                    dominant-baseline="middle"
                    :fill="$theme.primaryColor"
                    style="font-size:20px; font-family: robotoblack; transform: rotate(90deg); transform-origin: center center;"
                  >{{Math.ceil(selectedEnroll.progress)}}%</text>
                </svg>
            </div>
          </div>

          <div class="row groups"
          v-show="(((selectedEnroll || {}).telegramGroups || []).length > 0 || ((selectedEnroll || {}).whatsAppGroups || []).length > 0) || ((selectedEnroll || {}).facebookGroups || []).length > 0">

              <div

                :style="({color: $theme.primaryColor})" class="groupsTitle" >Grupos deste curso</div>
              <div class="groupBox"
              v-f="((selectedEnroll || {}).whatsAppGroups || []).length > 0">
                <div class="groupPlataform">
                  <iconWhatsapp />
                </div>
                <div class="groupName"
                :style="($theme.darkTheme ? '' : 'color: #333333')">
                  {{ selectedEnroll.whatsAppGroups }}
                </div>
              </div>
               <div class="groupBox"
               v-f="((selectedEnroll || {}).telegramGroups || []).length > 0">
                <div class="groupPlataform"
                >
                  <iconTelegram />
                </div>
                <div class="groupName"
                :style="($theme.darkTheme ? '' : 'color: #333333')">
                {{ selectedEnroll.whatsAppGroups }}
                </div>
              </div>
          </div>
      </div>

      </li>
      <!-- Pesquisa -->
      <li
        class="pesquisa"
        :style="($theme.darkTheme ? 'background: #0d0d13' : 'background: #F2F3F7; color: #ff6600')"
      >
        <div class="nav-wrapper accordion-color">
          <form>
            <div class="input-field container">
              <input
                id="search"
                type="search"
                placeholder="Pesquise aqui"
                :style="($theme.darkTheme ? 'color: #fff' : 'background: #F2F3F7; color: #333333')"
                required
                v-model="searchFilter"
                @input="updateFilter($event.target.value)"
                v-on:keydown.enter.prevent
              />

              <label class="label-icon" for="search">
                <i class="material-icons" :style="{ color: $theme.primaryColor }">search</i>
              </label>

              <label
                v-show="this.searchFilter != ''"
                class="label-icon-clear active"
                for="search"
                @click="clearSearch()"
              >
                <i class="material-icons" :style="{ color: $theme.primaryColor}">close</i>
              </label>
            </div>
          </form>
        </div>
      </li>

      <!-- Detalhes do curso -->
      <ul v-if="selectedEnroll" ref="accordion" class="collapsible collapsible-left">
        <!-- classes -->
        <li
          :id="'clss-'+ clss.id"
          :class="['clss-'+ clss.id , 'modules modules-left']"
          :data-accordion-index="index"
          v-for="(clss, index) in filtered.classes"
          :key="clss.id"
        >
          <div
            class="collapsible-header no-padding module-header accordion-bg-1"
            :style="($theme.darkTheme ? '' : 'background: #F2F3F7')"
          >
            <img :src="getClassImage(clss)" class="courseImg imgMaxWidth" alt />
            <div class="main-module ">
              <div class="module-info">
                <!-- <div class="module-label" :style="($theme.darkTheme ? '' : 'color: #333333')">
                  <i
                    v-if="clss.state == 'Unpublished'"
                    style="font-size: 16px; margin-right: 5px; width: auto;"
                    class="material-icons"
                  >lock</i>
                  <i
                    v-if="(clss.state == 'Scheduled' && clss.releasedDate)"
                    style="font-size: 16px; margin-right: 5px; width: auto;"
                    class="material-icons"
                  >lock</i>
                  Módulo {{formatClassTitle(clss)}}
                </div> -->

                <circle-progress
                  class="obj-progress col s1"
                  :percent="(progressModuleTotal(clss))"
                  >
                </circle-progress>
                <span>

                </span>
                <div class="obj-separator-mod"
                :style="($theme.darkTheme ? 'borderLeft: 2px solid #FFFFFF99' : 'borderLeft: 2px solid #c5c5c5')">
                </div>

                <div
                  class="module-qty"

                >
                {{finishedTotal(clss.learningObjects)}} / {{clss.learningObjects.length}}
              </div>
              </div>

              <div
                class="module-name"

              >
                <div class="line-clamp-3">{{clss.title}}</div>
              </div>
            </div>
          </div>
          <div v-show="classRouteActive(clss)" class="collapsible-body">
            <!-- OBJETOS DE APRENDIZAGEM -->
            <ul class="collapsible">
              <li
                :class="[('lbj-'+learningObject.id), 'learningObjects accordion-bg-2', {'active': lObjActive(learningObject)}]"
                :style="($theme.darkTheme ? 'background: #0D0D0D' : 'background: #F2F3F7')"
                v-for="(learningObject, index) in clss.learningObjects"
                :key="'collapsible_lo_' + learningObject.id"
              >
                <div class="obj-details accordion-padding-3">
                  <i
                    v-if="learningObject.state == 'Unpublished'"
                    style="font-size: 16px; margin-right: 5px; width: auto;"
                    class="material-icons"
                  >lock</i>
                  <i
                    v-if="(learningObject.state == 'Scheduled' && learningObject.releasedDate)"
                    style="font-size: 16px; margin-right: 5px; width: auto;"
                    class="material-icons"
                    v-tooltip.top="{content:formatLearningObjectTitle(learningObject, clss.state, clss.releasedDate)}"
                  >lock</i>
                  <circle-progress-small
                    v-if="learningObject.state != 'Unpublished' && !learningObject.releasedDate"
                    class=" obj-progress-small small col s1"
                    :percent="learningObject.progress"

                    @change="(e)=>progressObjChange(e.target.checked, selectedEnroll, learningObject, clss)"
                  ></circle-progress-small>

                  <div v-if="(index+1) != (clss.learningObjects.length )" class="obj-separator"
                  :style="($theme.darkTheme ? 'borderLeft: 2px solid #FFFFFF99' : 'borderLeft: 2px solid #c5c5c5')"
                  >
                  </div>

                  <div
                    class="obj-name col s8 sidenav-close line-clamp-2"
                    :style="($theme.darkTheme ? '' : 'color: #333333'), (lObjActive(learningObject) ? {color: $theme.primaryColor, fontWeight: 600} : '')"
                    :class="[(learningObject.state == 'Unpublished' || (learningObject.state == 'Scheduled' && learningObject.releasedDate) ? 'unpublished' : '') ]"
                    @click="navigateToClass({courseId:selectedEnroll.courseId,moduleId:0,disciplineId:clss.disciplineId,classId:clss.id,learningObjectId:learningObject.id})"
                  >{{learningObject.title}}</div>
                  <div
                    class="obj-time col s2"
                    :style="($theme.darkTheme ? '' : 'color: #333333')"
                  >{{getDuration(learningObject.broadcast)}}</div>
                </div>
              </li>
            </ul>
          </div>
        </li>

        <!-- Módulos -->
        <li
          :class="{'modules':true, 'active': classRouteActive(mod), 'show-specific-class': classRouteActive(mod)}"
          v-for="mod in filtered.modules"
          :key="'collpasible_mod_' + mod.id"
        >
          <div
            class="collapsible-header no-padding module-header accordion-bg-1"
            :style="($theme.darkTheme ? '' : 'background: #F2F3F7')"
          >
            <img :src="getModuleImage(mod)" class="courseImg imgMaxWidth" alt />
            <div class="main-module container">
              <div class="module-info">
                <!-- <div class="module-label" :style="($theme.darkTheme ? '' : 'color: #333333')">Módulo</div> -->
                <div
                  class="module-qty"
                  :style="($theme.darkTheme ? '' : 'color: #333333')"
                >{{progressModuleDiscipline(mod)}}</div>
              </div>

              <div
                class="module-name"
                :style="($theme.darkTheme ? '' : 'color: #000; fontWeight: 500')"
              >
                <div class="line-clamp-3">{{mod.name}}</div>
              </div>
            </div>
          </div>
          <div v-show="classRouteActive(mod)" class="collapsible-body accordion-bg-2 menu-aco">
            <!-- NÍVEL 2 - AULAS -->
            <ul class="collapsible">
              <!-- Aula -->
              <li
                :class="{'classes':true, 'active': classRouteActive(clss)}"
                v-for="clss in mod.classes"
                :key="'collapsible_class_' + clss.id"
              >
                <div
                  :class="{'collapsible-header':true, 'classes-header':true, 'container':true, 'transparent':true}"
                >
                  <div class="classes-info">
                    <div class="classes-name">
                      <i class="material-icons iconadd">add</i>
                      <i class="material-icons iconremove">remove</i>
                      {{clss.title}}
                    </div>
                    <div
                      class="classes-qty"
                    >{{finishedTotal(clss.learningObjects)}}/{{clss.learningObjects.length}}</div>
                  </div>
                </div>
                <div v-show="classRouteActive(clss)" class="collapsible-body">
                  <!-- NÍVEL 3 - OBJETOS DE APRENDIZAGEM -->
                  <ul class="collapsible">
                    <!-- Objeto de Aprendizagem -->
                    <li
                      class="learningObjects accordion-bg-3"
                      v-for="learningObject in clss.learningObjects"
                      :key="'collapsible_lo_' + learningObject.id"
                    >
                      <div :class="{'active': classRouteActive(clss)}" class="obj-details container accordion-padding-3">
                        <circle-progress
                          class="obj-progress col s1"
                          :percent="learningObject.progress"
                          :icon="menuIcon(learningObject)"
                          @change="(e)=>progressObjChange(e.target.checked, selectedEnroll, learningObject, clss)"
                        ></circle-progress>

                        <div
                          class="obj-name col s8 sidenav-close line-clamp-3"
                          :style="($theme.darkTheme ? '' : 'color: #333333'), (lObjActive(learningObject) ? {color: $theme.primaryColor, fontWeight: 600} : '')"
                          @click="navigateToClass({courseId:selectedEnroll.courseId,moduleId:mod.id,disciplineId:clss.disciplineId,classId:clss.id,learningObjectId:learningObject.id})"
                        >{{learningObject.title}}</div>
                        <div
                          class="obj-time col s2"
                          :style="($theme.darkTheme ? '' : 'color: #333333')"
                        >{{getDuration(learningObject.broadcast)}}</div>
                      </div>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </li>
      </ul>
    </ul>
    <!-- RIGHT SIDEBAR - NOTIFICAÇÕES -->
    <ul
      id="sidenav-2"
      :class="{'sidenav':true, 'side-right':true}"
      :style=" (selectedEnroll ? ($theme.darkTheme ? 'background-color: #0D0D13' : 'background-color: #ffffff') : ('background-color: #ffffff'))"
    >
      <li>
        <div></div>
        <div
          class="menu-notify-header container center-align"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))"
        >
          <a href="javascript:;">
            <i
              class="material-icons left sidenav-close"
              :style="(!selectedEnroll ? 'color: #0564b1' : {color: $theme.primaryColor})"
            >arrow_back</i>
          </a>
          <span>NOTIFICAÇÕES</span>
        </div>
      </li>

      <li v-for="notification in notifications" :key="'notification_' + notification.id">
        <div class="container notify" @click="redirectToNotification(notification)">
          <div style="width:100%">
            <div
              class="notify-title"
              :style="(!selectedEnroll ? '#0564b1' : {color: $theme.primaryColor})"
            >{{notification.title}}</div>

            <div
              class="notify-text line-clamp-4"
              :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))"
            >
              <a class="notify-link" href="javascript:;">
                <span v-if="notification.text.length <= 50">{{ notification.text }}</span>
                <span v-else>{{ notification.text.substr(0,50) + " ..." }}</span>
              </a>
            </div>

            <div
              class="notify-date"
              :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))"
            >{{brDate(notification.createAt)}}</div>
          </div>
        </div>
      </li>
    </ul>
    <!-- RIGHT SIDEBAR - MENU	-->
    <ul
      id="sidenav-3"
      :class="{'sidenav':true, 'side-right':true}"
      :style=" (selectedEnroll ? ($theme.darkTheme ? 'background: #0d0d13' : 'background: #ffffff') : ('background: #ffffff'))"
    >
      <li v-show="selectedEnroll">
        <div
          :class="{'user-view':false, 'center-align':true, 'menu-right-header':true}"
          :style="{ background : $theme.secondaryColor}"
        >
          <img
            class="img-logo-header-g"
            :src="selectedEnroll.image"
            alt
            v-if="!loading && selectedEnroll && courseLogoVisible"
            @error="courseLogoVisible = false"
          />
        </div>
      </li>
      <li v-show="!selectedEnroll">
        <div :class="{'user-view':false, 'center-align':true, 'menu-right-header':true}">
          <img class="img-logo-header-g" :src="$appLogoSrc" alt />
        </div>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->
      <li v-show="selectedEnroll">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="navToCourse()"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <IconHome />
          </i>
          Página inicial
        </a>
      </li>
      <li>
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true }"
          @click="showModal('profile')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/user.svg" alt /> -->
            <IconProfile :selectedEnroll="selectedEnroll" />
          </i>
          Perfil
        </a>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->

      <li v-show="selectedEnroll && !uniqueShopWindowId">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="navTo('/p/my-courses')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/book.svg" alt /> -->
            <IconMyCourses />
          </i>
          Meus Cursos
        </a>
      </li>
      <li v-show="(selectedEnroll && selectedEnroll.shopWindowId) || uniqueShopWindowId">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true }"
          @click="navToCourse(!selectedEnroll ? uniqueShopWindowId : undefined)"
         :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <!-- <i class="material-icons" :style="(!selectedEnroll ? 'color: #0564b1' : {color: $theme.primaryColor})">play_arrow</i> -->
          <i class="material-icons">
            <!-- <img src="@/assets/icons/book.svg" alt /> -->
            <IconTrustflix />
          </i>
          Home
        </a>
      </li>

      <li>
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="showModal('configuration')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <IconConfig :selectedEnroll="selectedEnroll" />
          </i>
          Configurações
        </a>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->
      <li v-show="selectedEnroll">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="showModal('notes')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/notes-2.svg" alt /> -->
            <IconNotes />
          </i>
          Anotações
        </a>
      </li>


      <li
        v-show="(((selectedEnroll || {}).telegramGroups || []).length > 0 || ((selectedEnroll || {}).whatsAppGroups || []).length > 0) || ((selectedEnroll || {}).facebookGroups || []).length > 0"
      >
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="showModal('mygroup')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/notes-2.svg" alt /> -->
            <IconMyGroup />
          </i>
          Meus Grupos
        </a>
      </li>


      <li v-show="emiteCertificado">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close':true}"
          @click="showModal('mycertificate')"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
        >
          <i class="material-icons icon-certificate">
            <!-- <img src="@/assets/icons/notes-2.svg" alt /> -->
            <IconMyCertificate />
          </i>
          Meus Certificados
        </a>
      </li>

      <!-- <li>
          <a
            href="javascript:;"
            :class="{ 'sidenav-close':true}"
            @click="showModal('support')"
           :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
          >
             <i class="material-icons" >
              <img src="@/assets/icons/help-circle.svg" alt />
            </i>
            Suporte
          </a>
      </li>-->
      <div class="menu-right-footer">
        <div>
          <ul>
            <li>
              <a
                href="javascript:;"
                @click="logout()"
                :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
              >
                <i class="material-icons">
                  <!-- <img src="@/assets/icons/log-out.svg" alt /> -->
                  <IconLogout :selectedEnroll="selectedEnroll" />
                </i>
                Sair
              </a>
            </li>
          </ul>
        </div>
        <div
          class="footer-version"
          :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
          :title="'Tema: ' + layoutTheme"
        >{{version}}</div>
      </div>
    </ul>
  </header>
</template>

<script>
import GlobalSearchBar from '@/components/GlobalSearchBar/index.vue';
import { AuthService } from "../services/auth.service";
import { ShopWindowsService } from "../services/shopWindow.service";

import CircleProgress from "@/components/CircleProgress";
import CircleProgressSmall from "@/components/CircleProgressSmall";
import M from "materialize-css";
import { mapState, mapGetters } from "vuex";
import Autocomplete from "@/components/Autocomplete";
import { isMobile } from "mobile-device-detect";
import { formatDate } from "@/filters/formatDate";
import IconHome from "@/components/UI/IconHome";
import IconProfile from "@/components/UI/IconProfile";
import IconMyCourses from "@/components/UI/IconMyCourses";
import IconTrustflix from "@/components/UI/IconTrustflix";
import IconConfig from "@/components/UI/IconConfig";

import IconClasses from "@/components/UI/IconClasses";
import IconMenuDownload from "@/components/UI/IconMenuDownload";
import IconModules from "@/components/UI/IconModules";
import IconWhatsapp from "@/components/UI/IconWhatsapp";
import IconTelegram from "@/components/UI/IconTelegram";

import IconNotes from "@/components/UI/IconNotes";
import IconMyGroup from "@/components/UI/IconMyGroup";
import IconMyCertificate from "@/components/UI/IconMyCertificate";
import IconLogout from "@/components/UI/IconLogout";
import IconMenu from "@/components/UI/IconMenu";
import learningObject from '../store/modules/learningObject';


export default {
  name: "Header",
  props: ["layoutTheme", "coursePreview"],
  components: {
    GlobalSearchBar,
    CircleProgress,
    CircleProgressSmall,
    Autocomplete,
    IconHome,
    IconProfile,
    IconMyCourses,
    IconTrustflix,
    IconConfig,
    IconNotes,
    IconMyGroup,
    IconMyCertificate,
    IconLogout,
    IconMenu,
    IconClasses,
    IconMenuDownload,
    IconModules,
    IconWhatsapp,
    IconTelegram
  },
  data() {
    return {
      show: false,
      searchFilter: "",
      userLogoVisible: true,
      courseLogoVisible: true,
      loading: true,
      version: process.env.VUE_APP_APP_VERSION,
      leftMenuRef: null,
      showSpecificClass: null,
      showSpecificLearningObject: null,
      accordionRef: null,
      isDesktop: false,
      selectedClassroom:""

    };
  },
  computed: {
    ...mapState({
      selectedEnroll: (state) => state.enroll.current,
      enrolls: (state) => state.enroll.enrolls,
      isPreview: (state) => state.enroll.isPreview,
      currentProfile: (state) => state.user.currentUser,
      sidenavIsOpen: (state) => state.config.sidenavIsOpen,
      shopWindow: (state) => state.shopWindow,
      notifications: (state) => state.notification.notifications
    }),
    ...mapGetters({
      filtered: "enroll/filtered",
      autocompleteSearchData: "enroll/autocomplete",
    }),
    unreadNotifications() {
      if (this.notifications) {
        return this.notifications.filter((not) => {
          return not.readOn == null;
        });
      }
      return false;
    },
    isMobileDevice() {
      return isMobile;
    },
    courseProgress() {
      return 251 - (251 * this.selectedEnroll.progress) / 100;
    },
    emiteCertificado(){
      if (!this.selectedEnroll) return
      if (this.selectedEnroll.settings.enableCertificate) {
        return true;
        }
    },
    uniqueShopWindowId() {
      // gets unique shopWindow Id
      if (!this.enrolls) return
      const firstProducer = this.enrolls[0];
      if (
        firstProducer?.shopWindowId &&
        this.enrolls.every(
          (item) =>
            item.applicationId === firstProducer?.applicationId &&
            item.shopWindowId
        )
      )
        {
          return firstProducer.shopWindowId;
        }

      return false
    },
    shopwindowPrimaryColor() {
      return this.selectedEnroll &&
        this.selectedEnroll?.shopWindowId === this.shopWindow?.id
        ? this.shopWindow.primaryColor
        : undefined;
    }
  },
   updated() {
    this.$nextTick(() => {
      this.refreshAccordion();
      //TODO: comentado, mas não houve muidança de coportamento aparente!
      //this.refreshRightNav();

    });
  },
    async mounted() {
    this.MaterializeInits();
    this.handleWindowResize();
    this.clearSearch();
    this.$store.dispatch("config/setSidenavState", true);
    this.$store.state.config.fixedMenu = true;

    this.loading = false;
    window.addEventListener('resize', this.handleWindowResize);

    if (this.selectedEnroll) this.onOpenLeftNav();
  },
  destroyed() {
    window.removeEventListener('resize', this.handleWindowResize);
  },
  watch: {
    filtered() {
      this.refreshAccordion();
    },
    $route(to, from) {
      this.MaterializeInits();
      switch (to.name) {
        case "class":
          const enrollId = to.params.enrollId;
          const learningObjectId = to.params.id;
          break;
      }
    },
    $profilePic() {
      this.userLogoVisible = true;
    },
    selectedEnroll(value) {
      this.courseLogoVisible = true;
      if (value) {
        // Overrides page theme with the shop-windows one
        const isOnShopWindow = Boolean(value.shopWindowId);
        const shopWindowPrimaryColor = isOnShopWindow
          ? this.$store.state.shopWindow?.primaryColor
          : undefined;

        const newTheme =
          (!isOnShopWindow || !shopWindowPrimaryColor)
            ? {
                darkTheme: value.settings.darkMode,
                primary: value.settings.primaryColor,
                secondary: value.settings.secondaryColor
              }
            : {
                darkTheme: true,
                primary: shopWindowPrimaryColor ?? value.settings.primaryColor,
                secondary: '#0d0d13' // Navbar background
              };

        this.$updateTheme(newTheme);
      }
    }
  },
  methods: {
      selectClassroom(event){
          this.classRoom = event.target.value;

          if (this.classRoom == "allClassroom"){
              this.resetDefault();
              return;
          }
          this.refreshSelectedClassroom();
      },

      refreshSelectedClassroom(){
        this.resetDefault();

        for (let i = 0; i < this.selectedEnroll.classes.length; i++) {
          var module = this.selectedEnroll.classes[i];

          for (let t = 0; t <= module.classroom.length; t++) {
              var classroomModule = module.classroom[t];
              var moduleId = "clss-" + module.id;
              var contentId = "content-" + module.id;

              if (this.classRoom != classroomModule){
                 document.getElementById(moduleId).classList.add('hide')
                 document.getElementById(contentId).classList.add('hide')
              }else{
                 document.getElementById(moduleId).classList.remove('hide')
                 document.getElementById(contentId).classList.remove('hide')
                break;
              }
          }
          }
      },
    resetDefault(){
          for (let i = 0; i < this.selectedEnroll.classes.length; i++) {
            var moduleId = "clss-" + this.selectedEnroll.classes[i].id;
            var contentId = "content-" + this.selectedEnroll.classes[i].id;
            document.getElementById(moduleId).classList.remove('hide')
            document.getElementById(contentId).classList.remove('hide')
          }
      },
    MaterializeInits() {
      this.refreshAccordion();

      this.refreshLeftNav();

      this.refreshRightNav();

      this.selectClassroomPreview();
    },
    refreshAccordion() {
      var collLeft = document.querySelectorAll(".collapsible-left");
      this.leftMenuAccordionRef = M.Collapsible.init(collLeft, {
        accordion: true,
      })[0];
    },
    refreshRightNav() {
      var sideRight = document.querySelectorAll(".side-right");
      M.Sidenav.init(sideRight, { edge: "right" });
    },
    refreshLeftNav() {
      var sideLeft = document.querySelectorAll(".sidenav-left");
      var instSideLeft = M.Sidenav.init(sideLeft, {
        onOpenStart: this.onOpenLeftNav,
        onCloseStart: this.onCloseLeftNav,
      });
      this.leftMenuRef = instSideLeft[0];
    },
    selectClassroomPreview(){
        document.addEventListener('DOMContentLoaded', function() {
        var elems = document.querySelectorAll('select');
        M.FormSelect.init(elems);
  });
    },
    onOpenLeftNav() {
      if (this.$route.name != "class") {
        var menuCont = document.querySelectorAll("#sidenav-1");
        menuCont[0].scrollTop = 0;
        return false;
      }

      this.scrollToCurrentLearningObject();
    },

    onCloseLeftNav() {
      if (this.leftMenuAccordionRef) {
        setTimeout(() => {

          this.leftMenuAccordionRef.close(this.leftMenuAccordionIndex);
        }, 200)
      }
    },
    toggleSideMenu() {
      if (!this.isDesktop) return;
      if (this.sidenavIsOpen) this.onCloseLeftNav();
      else this.onOpenLeftNav();

      this.$store.dispatch("config/setSidenavState");
    },
    handleWindowResize() {
      this.isDesktop = window.innerWidth > 1300;
    },
    scrollToCurrentLearningObject() {
      var learningObj;

      for (var i = 0; i < this.selectedEnroll.classes.length; i++) {
        const lobj = this.selectedEnroll.classes[i].learningObjects.find(
          (lo) => {
            return lo.id == this.$route.params.id;
          }
        );
        if (lobj) {
          learningObj = lobj;
          break;
        }
      }

      if (!learningObj) return;

      var accordionClass = document.querySelectorAll(
        ".clss-" + learningObj.classId
      )[0];
      this.leftMenuAccordionIndex = accordionClass.getAttribute(
        "data-accordion-index"
      );
      this.leftMenuAccordionRef.open(this.leftMenuAccordionIndex);
      setTimeout(function () {
        var selectedLearningObject = document.querySelectorAll(
          ".lbj-" + learningObj.id
        );
        var menuCont = document.querySelectorAll("#sidenav-1");
        menuCont[0].scrollTop = selectedLearningObject[0].offsetTop;
      }, 300);
    },
    menuIcon(learningObj) {
      if (learningObj.forms) {
        if (learningObj.forms.type == "SurveyResearch") return "help_outline";
        if(learningObj.forms.type == 'Proof')
        return 'assignment';
      }
      if (learningObj.externalContent) {
        if(learningObj.externalContent.icon)
          return learningObj.externalContent.icon;
        else
        return "thumb_up";
      }
    },
    clearSearch() {
      this.$store.dispatch("enroll/updateFilter", (this.searchFilter = ""));

      if (this.leftMenuAccordionRef) this.leftMenuAccordionRef.close();

      // fechar o menu
    },
    classRouteActive(clss) {
      if (this.$route.name != "class") return false;

      for (let i in clss) {
        if (
          clss.learningObjects.filter((lObj) => {
            return parseInt(this.$route.params.id) == lObj.id;
          }).length
        ) {
          return true;
        }
      }

      return false;
    },
    lObjActive(lObj) {
      if (this.$route.name != "class") return false;

      return parseInt(this.$route.params.id) == lObj.id;
    },



    formatClassTitle(clss) {
      if (clss.state == "Scheduled" && clss.releasedDate) {
        return " (Liberado em " + formatDate(new Date(clss.releasedDate)) + ")";
      }
      //return clss.title;
    },
    formatLearningObjectTitle(lobj, classState, clssReleasedDate) {
      if (lobj.state == "Scheduled" && lobj.releasedDate) {
        return "liberado em " + formatDate(new Date(lobj.releasedDate));
      }
      return lobj.title;
    },
    getClassImage(clss) {
      if (!clss || !clss.learningObjects || clss.learningObjects.length == 0)
        return "@/assets/images/default-course-image.png";

      return this.learningObjectImageByType(clss.learningObjects[0]);
    },
    getModuleImage(mod) {
      var img = "@/assets/images/default-course-image.png";
      if (!mod) return img;

      if (!mod.classes || mod.classes.length == 0) return img;

      var firstClass = mod.classes[0];

      if (
        !firstClass ||
        !firstClass.learningObjects ||
        firstClass.learningObjects.length == 0
      )
        return img;

      return this.learningObjectImageByType(firstClass.learningObjects[0]);
    },
    learningObjectImageByType(lo) {
      if (lo.broadcast) return lo.image + "?h=249";

      return lo.image;
    },
    updateFilter(filter) {
      this.shouldMaterializeInit = false;
      this.$store.dispatch("enroll/updateFilter", filter);

      if (this.leftMenuAccordionRef) {
        for (
          var i = 0;
          document.getElementsByClassName("collapsible-body").length > i;
          i++
        ) {
          document
            .getElementsByClassName("collapsible-body")
            [i].setAttribute("style", "display:block");
        }
      }
    },
    async logout() {
      await AuthService.makeLogout();
      window.location.reload();
    },
    finishedLearningObject(progress) {
      return progress == 100;
    },
    getDuration(broadcast) {
      if (!broadcast) return "";

      function fmtMSS(s) {
        return (s - (s %= 60)) / 60 + (9 < s ? ":" : ":0") + s;
      } // formata
      return fmtMSS(broadcast.duration);
    },
    finishedTotal(learningObjects) {
      var total = learningObjects.filter((lobj) => {
        return lobj.progress == 100;
      }).length;
      return total;
    },
    progressModuleTotal(clss){
      if (this.finishedTotal(clss.learningObjects) == clss.learningObjects.length){
        return 100;
      }
        return 0;
    },

    progressModuleDiscipline(mod) {
      var total = mod.classes.length;
      var progress = mod.classes.filter((clss) => {
        //quantas classes foram finalizadas
        for (let i in clss.learningObjects) {
          if
            (this.finishedTotal(clss.learningObjects) == clss.learningObjects.length)
          {
            return true;
          }
        }
        return false;
      }).length;
      return progress + "/" + total;
    },
    progressLObj(mod) {
      var total = mod.totalLearningObjects;
      var totalLObj = 0;

      for (let i in mod.classes) {
        for (let j in mod.classes[i].learningObjects) {
              if (mod.classes[i].learningObjects[j].progress == 100) {
                totalLObj++;
              }
          }
      }
      return totalLObj + "/" + total;
    },
    brDate(strDate) {
      var dt = new Date(strDate);
      var dtMonths = [
        "jan",
        "fev",
        "mar",
        "abr",
        "mai",
        "jun",
        "jul",
        "ago",
        "set",
        "out",
        "nov",
        "dez",
      ];

      return (
        (dt.getDate() < 10 ? "0" + dt.getDate() : dt.getDate()) +
        " de " +
        dtMonths[dt.getMonth()]
      );
    },
    navToCourse(defaultTarget) {
      if (this.isPreview) return;
      const shopwindowId = this.selectedEnroll?.shopWindowId ?? defaultTarget;

      if (shopwindowId) {
        this.leftMenuRef.close()
        ShopWindowsService.setCurrentShopWindow({ id: shopwindowId });
        this.$router.push({ name: 'trustflix' });
      } else {
        this.$router.push({
          name: 'course',
          params: { id: this.selectedEnroll.id }
        });
      }
    },
    navigateToClass(eventData) {
      if (this.isPreview) return;

      this.$router.push({
        name: "class",
        params: {
          enrollId: this.selectedEnroll.id,
          id: eventData.learningObjectId,
        },
      });
    },
    navigateToCourse(eventData) {
      if (this.isPreview) return;

      this.$router.push({
        name: "course",
        params: {
          id: eventData.courseId,
        },
      });
    },
    redirectToNotification(notification) {
      if (this.isPreview) return;

      window.location = notification.linkRedirect;
    },
    navTo(path) {
      if (this.isPreview) return;

      if (path == "/p/my-courses") {
        this.$updateTheme({
          darkTheme: false,
          primary: "#0564b1",
          secondary: "#ffffff",
        });
      }

      this.$router.push(path);
    },
    showModal(name) {
      if (this.isPreview) return;

      this.$modal.show(name);
    },
    readNotifications() {
      for (let i in this.unreadNotifications) {
        this.$store.dispatch(
          "notification/read",
          this.unreadNotifications[i].id
        );
      }
    },
    async progressObjChange(complete, enroll, learningObject, clss) {
      if (this.isPreview) return;
      try {
        await this.$store.dispatch("enroll/setProgress", {
          enrollId: enroll.id,
          courseId: enroll.courseId,
          moduleId: learningObject.moduleId,
          disciplineId: learningObject.disciplineId,
          classId: learningObject.classId,
          learningObjectId: learningObject.id,
          broadcastId: learningObject.broadcast
            ? learningObject.broadcast.id
            : null,
          progress: complete ? 100 : 0,
        });
        this.$store.dispatch("enroll/getCourseProgress", enroll.id);
      } catch (error) {
        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message,
        });
      }
    },
    openLeftMenu(classId) {
      var modulesElements = document.querySelectorAll(".collapsible-left");

      var selectedClassElement = null;

      for (var i = 0; i < modulesElements[0].children.length; i++) {
        if (
          modulesElements[0].children[i].className.indexOf("clss-" + classId) >
          -1
        ) {
          selectedClassElement = modulesElements[0].children[i];
          this.leftMenuAccordionIndex = i;
          break;
        }
      }
      // this.leftMenuRef.open();
      if (!this.isDesktop) {
        this.leftMenuRef.open()
      } else if (!this.sidenavIsOpen) {
        this.$store.dispatch("config/setSidenavState");
        this.onCloseLeftNav()
      } else {
        this.onOpenLeftNav()
      }

      if (this.leftMenuAccordionRef) {
        this.leftMenuAccordionRef.open(this.leftMenuAccordionIndex);

        setTimeout(function () {
          var menuCont = document.querySelectorAll("#sidenav-1");
          menuCont[0].scrollTop = selectedClassElement.offsetTop;
        }, 200);
      }
    },
  },
  filters: {
    imageHandler(url) {
      const isStored = url.startsWith('https://');

      return isStored ? url + '?h=35' : url;
    }
  }
};
</script>

<style scoped lang="scss">
  .box-menu-top {
    width: 100%;
    padding: 0 20px;

    .menu-align a.sidenav-trigger {
      margin-left: 0;
    }
  }

  .icon-certificate{
        padding-top: 6px;
  }
</style>

<style lang="scss" >
@media screen and (min-width: 1300px) {
  #sidenav-1 {
    transition: transform 300ms ease-in-out;
    top: 64px;
    height: calc(100% - 64px);
    z-index: 989 !important;

    &.sidenav-expanded {
      transform: translateX(0) !important;
    }
  }
}

.ml0 {
  margin-left: 0;
}

.mr1 {
  margin-right: 10px;
}

.img-logo-header {
  width: 45vw;
  max-width: 180px;
  height: 45px; /* 80px;*/
  object-fit: contain;
  object-position: left;
  cursor: pointer;
}

.img-logo-header-g {
  width: 70vw;
  max-width: 240px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: contain;
}

.menu-right-header-dark {
  background-color: #0d0d13 !important;
}

.menu-right-header-light {
  background-color: #f5f5f5;
}

.imgMaxWidth {
  max-width: 160px;
}

.notificationImg {
  max-width: 96px;
  max-height: 120px;
}

.courseImg {
  // opacity: 0.5;
  filter: grayscale(1) brightness(0.3);
  transition: opacity 0.5s ease-in-out;
}

.modules.active .courseImg {
  // opacity: 1;
  filter: grayscale(0) brightness(0.5);
  // border: 1px solid red;
}
.modules .main-module .module-info .obj-separator-mod {
  opacity: 0;
}
.modules.active .main-module .module-info .obj-separator-mod {
  opacity: 1;
  transition: opacity 0.4s ease-in-out;
  // filter: grayscale(0);
  // border: 1px solid red;
}

.header {
  z-index: 1;
  padding: 20px 0;
  position: relative;

  .is-active {
    color: #e01b3c;
  }

  a {
    /*color: #000;*/
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 15px;
    }

    &.side {
      font-size: 15px;

      .logout-button {
        cursor: pointer;
      }
    }
  }
}

.obj-progress {
  margin-right: 0px;
  svg{
    left: -1px !important;
  }
}

.obj-progress-small {
  margin-right: 12px;

    label{
      position: absolute;
      left: -2px;
      top: -2px;

      [type="checkbox"].filled-in + span:not(.lever):after {
        border-radius: 50%;
      }


      [type="checkbox"].filled-in:checked+span:not(.lever):after {
        width: 14px;
        height: 14px;
        top:  3px;
        left: 3px;
      }

      [type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
          background: transparent;
          border: 0;
          width: 14px;
          height: 14px;
          top:  3px;
          left: 3px;
      }

      [type="checkbox"].filled-in:checked+span:not(.lever):before {
        width: 5px;
        height: 9px;
        top:  2px;
        left: 5px;
        border-top: 2px solid transparent;
        border-left: 2px solid transparent;
        border-right: 2px solid #fff;
        border-bottom: 2px solid #fff;
        transform: rotateZ(37deg);
        transform-origin: 100% 100%;

      }

      [type="checkbox"].filled-in:not(:checked) + span:not(.lever):before {
        width: 5px;
        height: 9px;
        top:  2px;
        left: 5px;
      }

    }
    svg{
      // border: 1px solid rgb(254, 0, 208);
    }


}




.course-summary {
  font-family: roboto;
  font-size: 14px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bfbfbf;
  width: 65%;
  // margin-bottom: 3em;
}

.module-info {
  align-items: center;
}

.module-label {
  display: flex;
  align-items: center;
}

.course-summary li {
  line-height: 8px !important;
}

.search-header {
  width: 50%;
  height: 45px;
  z-index: 99;
  background-color: #131318;
  border-radius: 10px;
  // color: #ff6600;
}
.search-header-div {
  width: 100%;
}

.vue-tooltip {
  z-index: 9999 !important;
}

.collapsible-left{
  border-radius: 10px;
  overflow: hidden;
}

.sidenav .back-to-trustflix-bt {
  border-bottom: 0.5px solid rgba(127, 127, 127, 0.2);
  padding: unset;
  font-weight: unset;
  margin-bottom: 24px;

  .container {
    display: flex;
    align-items: center;
    justify-content: flex-start;

    height: 3em;

    i {
      padding: 8px 20px 8px 0;
    }
  }
}

.notify {
cursor: pointer;
text-decoration: none;
}

select{
  display: block !important;
}

.selectClassroom{
  width: 175px !important;
  height: 25px;
  padding: 0;
  margin-left: 5px;
  font-size: 14px;
}

.viewClassroom{
  font-size: 14px;
    display: flex;
    flex-direction: row;
    color: black;
    align-items: center;
    height: 35px;
}

.details{

  width: 100%;
  padding: 25px;
}

.obj-separator{
  border-left: 2px solid #FFFFFF99;
  // border-radius: 13px;
  height: 45px;
  position: relative;
  left: -25px;
  top: 31px;
}
.obj-separator-mod{
  border-left: 2px solid #FFFFFF99;
  // border-radius: 13px;
  height: 101px;
    position: absolute;
    left: 10px;
    top: 20px;
}

.menuTitle{
  color: var(--main-primary, #9747FF);
font-family: robotobold;
font-size: 24px;
font-style: normal;
font-weight: bold;
line-height: 30px;
padding-bottom: 15px;
}


.groupsTitle{
  font-family: Roboto;
font-size: 16px;
font-style: normal;
font-weight: 600;
line-height: normal;
}
.itemSummary{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;

  &.download{
    height: 35px;
    // padding: 8px 0;
    border-radius: 5px;
    text-decoration: underline;
    cursor: pointer;
  }

  .itemDetail{
    display: flex;
    align-items: center;

    span{
      padding-left: 5px;
    }
  }
}

.iconSummary{
  width: 20px;
  // height: 20px;
  color: #ffffff;
}

.course-progress{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 35%;
  padding-left: 15px;

  // min-height: 11em;
}

.progress-box{
  display: flex;
  flex-wrap: wrap;
  flex-direction: col;

  // padding: 0px;

}

.groupBox{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
.groupPlataform{
  display: flex;
  align-items: center;
  justify-content: center;
  // width: 35px;
  // height: 35px;
  border-radius: 5px;
  margin-right: 10px;



  svg{
    width: 28px;
    height: 28px;
  }
}

.groupName{
  font-family: Roboto;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  width: 80%;
}
</style>
