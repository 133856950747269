<template>
  <header :class="{ 'trustflix-header': true, 'navbar-fixed': this.$store.state.config.fixedMenu }">
    <nav class="main-menu">
      <div class="box-menu-top container navbar-content" v-if="!loading">
        <div
          :class="{ 'nav-wrapper': true, 'menu-align': true }"
        >
          <!-- LEFT SIDEBAR TRIGGER -->
          <a
            href="javascript:;"
            :data-target="isDesktop ? '' : 'trustflix-left-menu'"
            :class="{ 'menu-align ml0 mr1': true, 'sidenav-trigger': !isDesktop && !sidenavIsOpen }"
            @click="toggleSideMenu"
          >
            <!-- <img src="@/assets/icons/menu_branco.svg" alt /> -->
            <IconMenu />
          </a>
          <!-- <img src="@/assets/images/logo-pagtrust.png" class="valign-wrapper img-logo-header" alt srcset /> -->
          <img
            v-if="logo && !shopwindowIsLoading"
            :src="(logo)"
            class="valign-wrapper img-logo-header"
            @error="onLogoError"
            @load="onLogoLoad"
            alt
            ref="shopwindowLogo"
          />
        </div>

        <!-- NOTIFICATION SIDEBAR TRIGGER -->
        <div id="menu-right-group">
          <a
            href="javascript:;"
            data-target="notifications-sidenav"
            class="sidenav-trigger"
            @click="readNotifications()"
          >
            <div class="notify-count" v-show="unreadNotifications.length > 0">
              {{ unreadNotifications.length }}
            </div>
            <i
              :class="{ 'material-icons': true }"
              :style="{ color: $theme.secondaryText }"
              >notifications_none</i
            >
          </a>
          <!-- USER RIGHT SIDEBAR TRIGGER -->
          <a
            href="javascript:;"
            data-target="sidenav-3"
            id="sidenav3"
            class="sidenav-trigger"
          >
            <div
              class="menu-right-icon"
              :style="{ background: shopwindowIsLoading ? '#0D0D13' : '' }"
              >
              <img
                v-if="userLogoVisible"
                :src="$profilePic | imageHandler"
                class="menu-user-img"
                alt
                @error="userLogoVisible = false"
              />
              <template v-else>{{ $firstLetter }}</template>
            </div>
          </a>
        </div>
      </div>
    </nav>

    <!-- LEFT SIDEBAR	 -->
    <!-- não usar v-if -> não abre o menu -->
    <ul
      id="trustflix-left-menu"
      :class="{
        'sidenav sidenav-left': true,
        'sidenav-expanded': sidenavIsOpen && !shopwindowIsLoading
      }"
    >
      <!-- <li><h1>Hello Word</h1></li> -->
      <trustflix-sidenav @showModal="showModal" @clickInside="closeIfMobile" />
    </ul>
    <!-- RIGHT SIDEBAR - NOTIFICAÇÕES -->
    <ul
      id="notifications-sidenav"
      :class="{ sidenav: true, 'side-right': true }"
      :style="
        selectedEnroll
          ? $theme.darkTheme
            ? 'background-color: #0D0D13'
            : 'background-color: #ffffff'
          : 'background-color: #0D0D13'
      "
    >
      <li>
        <div></div>
        <div
          class="menu-notify-header container center-align"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #bfbfbf'
                : 'color: #333333'
              : 'color: #bfbfbf'
          "
        >
          <a href="javascript:;">
            <i
              class="material-icons left sidenav-close"
              >arrow_back</i
            >
          </a>
          <span>NOTIFICAÇÕES</span>
        </div>
      </li>

      <li
        v-for="notification in notifications"
        :key="'notification_' + notification.id"
      >
        <div class="container notify" @click="redirectToNotification(notification)">
          <div>
            <div
              class="notify-title"
              :style="
                !selectedEnroll ? 'green' : { color: $theme.primaryColor }
              "
            >
              {{ notification.title }}
            </div>

            <div
              class="notify-text line-clamp-4"
              :style="
                selectedEnroll
                  ? $theme.darkTheme
                    ? 'color: #bfbfbf'
                    : 'color: #333333'
                  : 'color: #bfbfbf'
              "
            >
              {{ notification.text }}
            </div>
            <div
              class="notify-date"
              :style="
                selectedEnroll
                  ? $theme.darkTheme
                    ? 'color: #bfbfbf'
                    : 'color: #333333'
                  : 'color: #bfbfbf'
              "
            >
              {{ brDate(notification.createAt) }}
            </div>
          </div>
        </div>
      </li>
    </ul>
    <!-- RIGHT SIDEBAR - MENU	-->
    <ul
      id="sidenav-3"
      :class="{ sidenav: true, 'side-right': true }"
      :style="
        selectedEnroll
          ? $theme.darkTheme
            ? 'background: #0d0d13'
            : 'background: #ffffff'
          : 'background: #0d0d13'
      "
    >
      <!-- <li v-show="selectedEnroll">
        <div
          :class="{
            'user-view': false,
            'center-align': true,
            'menu-right-header': true
          }"
          :style="{ background: $theme.secondaryColor }"
        >
          <img
            class="img-logo-header-g"
            :src="selectedEnroll.image"
            alt
            v-if="!loading && selectedEnroll"
            @error="
              $event.target.src = require('@/assets/images/default-course-image.png')
            "
          />
        </div>
      </li> -->
      <li>
        <div
          :class="{
            'user-view': false,
            'center-align': true,
            'menu-right-header': true
          }"
        >
          <!-- <img
            class="img-logo-header-g"
            src="@/assets/images/logo-pegtrust.svg"
            alt
          /> -->
          <AppLogo
            darkMode
            class="img-logo-header-g"
          />
        </div>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->
      <li v-show="selectedEnroll">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <IconHome />
          </i>
          Página inicial
        </a>
      </li>
      <li>
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="showModal('profile')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/user.svg" alt /> -->
            <IconProfile :selectedEnroll="selectedEnroll" />
          </i>
          Perfil
        </a>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->
      <li v-show="!uniqueShopWindowId">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="navTo('/p/my-courses')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/book.svg" alt /> -->
            <IconMyCourses />
          </i>
          Meus Cursos
        </a>
      </li>

      <li>
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="showModal('configuration')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <IconConfig :selectedEnroll="selectedEnroll" />
          </i>
          Configurações
        </a>
      </li>
      <!-- ESCONDER NA PAGINA DE CURSOS -->
      <!-- <li v-show="selectedEnroll">
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="showModal('notes')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <img src="@/assets/icons/notes-2.svg" alt />
            <IconNotes />
          </i>
          Anotações
        </a>
      </li> -->
      <li
        v-show="hasGroups"
      >
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="showModal('mygroup')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons">
            <!-- <img src="@/assets/icons/notes-2.svg" alt /> -->
            <IconMyGroup />
          </i>
          Meus Grupos
        </a>
      </li>

       <li v-show="emiteCertificado.length"

      >
        <a
          href="javascript:;"
          :class="{ 'sidenav-close': true }"
          @click="showModal('mycertificate')"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
        >
          <i class="material-icons icon-certificate">
            <IconMyCertificate />
          </i>
          Meus Certificados
        </a>
      </li>
      <!-- <li>
          <a
            href="javascript:;"
            :class="{ 'sidenav-close':true}"
            @click="showModal('support')"
           :style=" (selectedEnroll ? ($theme.darkTheme ? 'color: #ffffff' : 'color: #333333') : ('color: #333333'))"
          >
             <i class="material-icons" >
              <img src="@/assets/icons/help-circle.svg" alt />
            </i>
            Suporte
          </a>
      </li>-->
      <div class="menu-right-footer">
        <div>
          <ul>
            <li>
              <a
                href="javascript:;"
                @click="logout()"
                :style="
                  selectedEnroll
                    ? $theme.darkTheme
                      ? 'color: #ffffff'
                      : 'color: #333333'
                    : 'color: #ffffff'
                "
              >
                <i class="material-icons">
                  <!-- <img src="@/assets/icons/log-out.svg" alt /> -->
                  <IconLogout :selectedEnroll="selectedEnroll" />
                </i>
                Sair
              </a>
            </li>
          </ul>
        </div>
        <div
          class="footer-version"
          :style="
            selectedEnroll
              ? $theme.darkTheme
                ? 'color: #ffffff'
                : 'color: #333333'
              : 'color: #ffffff'
          "
          :title="'Tema: ' + layoutTheme"
        >
          {{ version }}
        </div>
      </div>
    </ul>
  </header>
</template>

<script>
import AppLogo from '@/components/UI/AppLogo.vue';
import { AuthService } from '../services/auth.service';

import CircleProgress from '@/components/CircleProgress';
import M from 'materialize-css';
import { mapState, mapGetters } from 'vuex';
import Autocomplete from '@/components/Autocomplete';
import { isMobile } from 'mobile-device-detect';
import { formatDate } from '@/filters/formatDate';
import IconHome from '@/components/UI/IconHome';
import IconProfile from '@/components/UI/IconProfile';
import IconMyCourses from '@/components/UI/IconMyCourses';
import IconConfig from '@/components/UI/IconConfig';
import IconNotes from '@/components/UI/IconNotes';
import IconMyGroup from '@/components/UI/IconMyGroup';
import IconMyCertificate from '@/components/UI/IconMyCertificate';
import IconLogout from '@/components/UI/IconLogout';
import IconMenu from '@/components/UI/IconMenu';

import TrustflixSidenav from '../components/Trustflix/Sidenav.vue';
import certificate from '../store/modules/certificate';

export default {
  name: 'Header',
  props: ['layoutTheme', 'coursePreview'],
  components: {
    AppLogo,
    CircleProgress,
    Autocomplete,
    IconHome,
    IconProfile,
    IconMyCourses,
    IconConfig,
    IconNotes,
    IconMyGroup,
    IconMyCertificate,
    IconLogout,
    IconMenu,
    
    TrustflixSidenav,
  },
  data() {
    return {
      show: false,
      searchFilter: '',
      notifications: this.$store.state.notification.notifications,
      userLogoVisible: true,
      loading: true,
      version: process.env.VUE_APP_APP_VERSION,
      leftMenuRef: null,
      showSpecificClass: null,
      showSpecificLearningObject: null,
      trustflixMenuIsOpen: true,
      materializeElems: [],
      logoIsValid: false,
      selectedEnroll: {}
    };
  },
  destroyed() {
    this.materializeElems.forEach(inst => {
        inst.destroy()
      }
    )
  },
  computed: {
    ...mapState({
      // selectedEnroll: (state) => state.enroll.current,
      isPreview: (state) => state.enroll.isPreview,
      currentProfile: (state) => state.user.currentUser,
      sidenavIsOpen: (state) => state.config.sidenavIsOpen,
      logo: (state) => state.shopWindow.logo,
      enrolls: (state) => state.enroll.enrolls,
      enrolledCoursesIds : (state) => state.shopWindow.enrolledCoursesIds ,
    }),
    ...mapGetters({
      filtered: 'enroll/filtered',
      autocompleteSearchData: 'enroll/autocomplete',
      isDesktop: 'dom/isDesktop',
      shopwindowIsLoading: 'shopWindow/isLoading',
      hasGroups: 'shopWindow/hasGroups'
    }),
    unreadNotifications() {
      if (this.notifications) {
        return this.notifications.filter((not) => {
          return not.readOn == null;
        });
      }
      return false;
    },
    isMobileDevice() {
      return isMobile;
    },

    emiteCertificado(){
      return this.enrolls.filter(item => item.settings.enableCertificate == true && this.enrolledCoursesIds?.includes(item.courseId));
    },

    uniqueShopWindowId() {
      // gets unique shopWindow Id
      if (!this.enrolls) return
      const firstProducer = this.enrolls[0];
      if (
        firstProducer?.shopWindowId &&
        this.enrolls.every(
          (item) =>
            item.applicationId === firstProducer?.applicationId &&
            item.shopWindowId
        )
      )
        {
          return firstProducer.shopWindowId;
        }

      return false
    },
  },
  updated() {
    this.$nextTick(() => {
      this.MaterializeInits()
    });
  },
  mounted() {
    this.MaterializeInits();
    this.loading = false;
    this.handleLogoValidity();

    this.$store.dispatch("config/setSidenavState", false);
  },
  watch: {
    $route(to, from) {
      this.MaterializeInits();
      switch (to.name) {
        case 'class':
          const enrollId = to.params.enrollId;
          const learningObjectId = to.params.id;
          break;
      }
    },
    $profilePic() {
      this.userLogoVisible = true;
    },
    // selectedEnroll(value) {
    //   if (value) {
    //     this.$updateTheme({
    //       darkTheme: value.settings.darkMode,
    //       primary: value.settings.primaryColor,
    //       secondary: value.settings.secondaryColor
    //     });
    //   }
    // }
  },
  methods: {
    MaterializeInits() {
      this.materializeElems = [this.refreshTrustflixMenu(), ...this.refreshRightNav()]
    },
    refreshRightNav() {
      var sideRight = document.querySelectorAll('.side-right');
      return M.Sidenav.init(sideRight, {
        edge: 'right'
      });
    },
    refreshTrustflixMenu() {
      const trustflixMenu = document.getElementById('trustflix-left-menu');

      return M.Sidenav.init(trustflixMenu, {
        edge: 'left',
        inDuration: 200,
        outDuration: 200,
        closeOnClick: true,
        onOpenStart: (elem) => {
          document
            .getElementsByClassName('main-menu')[0]
            .scrollIntoView({ behavior: 'smooth' });
        },
        onCloseStart: () => {
            this.toggleSideMenu();
        }
      });
    },
    onLogoLoad() {
      if (this.$refs.shopwindowLogo) this.$refs.shopwindowLogo.style.opacity = "1"
    },
    onLogoError() {
      if (this.$refs.shopwindowLogo) this.$refs.shopwindowLogo.style.opacity = "0"
    },
    async handleLogoValidity() {
      if (!this.logo) this.logoIsValid = false;
      const img = new Image();
      const validityCheck = new Promise((res) => {
        img.onerror = () => res(false);
        img.onload = () => res(true);
      })
      img.src = this.logo;
      const isValid = await validityCheck;

      this.logoIsValid = await isValid;
    },
    toggleSideMenu() {
      this.$store.dispatch("config/setSidenavState");
    },
    async logout() {
      await AuthService.makeLogout();
      window.location.reload();
    },
    closeIfMobile() {
      this.materializeElems[0]?.close();
    },
    brDate(strDate) {
      var dt = new Date(strDate);
      var dtMonths = [
        'jan',
        'fev',
        'mar',
        'abr',
        'mai',
        'jun',
        'jul',
        'ago',
        'set',
        'out',
        'nov',
        'dez'
      ];

      return (
        (dt.getDate() < 10 ? '0' + dt.getDate() : dt.getDate()) +
        ' de ' +
        dtMonths[dt.getMonth()]
      );
    },
    navToCourse() {
      if (this.isPreview) return;

      this.$router.push({
        name: 'course',
        params: { id: this.selectedEnroll.id }
      });
    },
    navigateToClass(eventData) {
      if (this.isPreview) return;

      this.$router.push({
        name: 'class',
        params: {
          enrollId: this.selectedEnroll.id,
          id: eventData.learningObjectId
        }
      });
    },
    navTo(path) {
      if (this.isPreview) return;

      if (path == '/p/my-courses') {
        this.$updateTheme({
          darkTheme: false,
          primary: '#0564b1',
          secondary: '#ffffff'
        });
      }

      this.$router.push(path);
    },
    showModal(name) {
      if (this.isPreview) return;

      this.$modal.show(name);
    },
    readNotifications() {
      for (let i in this.unreadNotifications) {
        this.$store.dispatch(
          'notification/read',
          this.unreadNotifications[i].id
        );
      }
    },
    redirectToNotification(notification) {
      if (this.isPreview) return;

      window.location = notification.linkRedirect;
    },
  },
  filters: {
    imageHandler(url) {
      const isStored = url.startsWith('https://');

      return isStored ? url + '?h=35' : url;
    }
  }
};
</script>

<style scoped lang="scss">
@font-face {
  font-family: 'Material Icons';
  font-display: block !important;
}

.main-menu,
.nav-wrapper {
  background-color: rgb(13, 13, 19);
}
.trustflix-header {
  --sidebar-func: ease;
}
@media screen and (min-width: 1300px) {
  #trustflix-left-menu {
    transition: transform 300ms var(--sidebar-func);

    &.sidenav-expanded {
      transform: translateX(0) !important;
    }
  }
  .nav-wrapper {
    transition: opacity 300ms ease-in-out;
    opacity: 1;
    &.nav-wrapper--hidden {
      opacity: 0;
      pointer-events: none;
    }
  }
}

.mr1 {
  margin-right: 10px;
}

.ml0 {
  margin-left: 0;
}


.main-menu .navbar-content {
  margin: 0 20px;
  width: 100%;
  max-width: unset;
}

#trustflix-left-menu {
  background: #0d0d13;
  height: calc(100% - 64px);
  top: 64px;
  height: 100%;
  max-width: 260px;
  overflow-x: hidden;
  box-shadow: none;

  transition: transform 350ms var(--sidebar-func);
}

.trustflix-header .menu-right-icon {
  background: var(--primary-color);
}

.trustflix-header i {
  &, & svg g {
    fill: var(--primary-color);
    color: var(--primary-color);
  }
}

.img-logo-header {
  width: 45vw;
  max-width: 180px;
  height: 45px; /* 80px;*/
  object-fit: contain;
  object-position: left;
  cursor: pointer;
  opacity: 0;
  transition: opacity 300ms ease-in;
}

.img-logo-header-g {
  width: 70vw;
  max-width: 240px;
  height: 120px;
  -o-object-fit: cover;
  object-fit: contain;
}

.menu-right-header-dark {
  background-color: #0d0d13 !important;
}

.menu-right-header-light {
  background-color: #f5f5f5;
}

.imgMaxWidth {
  max-width: 160px;
}

.notificationImg {
  max-width: 96px;
  max-height: 120px;
}

.courseImg {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.modules.active .courseImg {
  opacity: 0.3;
}

.header {
  z-index: 1;
  padding: 20px 0;
  position: relative;

  .is-active {
    color: #e01b3c;
  }

  a {
    /*color: #000;*/
    text-decoration: none;
  }

  .menu-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .menu {
    font-size: 25px;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
    align-items: center;

    li {
      padding: 15px;
    }

    &.side {
      font-size: 15px;

      .logout-button {
        cursor: pointer;
      }
    }
  }
}

.obj-progress {
  margin-right: 15px;
}

.course-summary {
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #bfbfbf;
  margin-bottom: 3em;
}

.course-summary li {
  line-height: 18px;
}

.search-header {
  width: 50%;
  height: 45px;
  z-index: 99;
  background-color: #131318;
  border-radius: 10px;
  // color: #ff6600;
}
.search-header-div {
  width: 100%;
}

.vue-tooltip {
  z-index: 9999 !important;
}

@media screen and (max-width: 600px) {
  #trustflix-left-menu {
    background: #0d0d13;
    height: calc(100% - 56px);
    top: 56px;
    padding-bottom: 0;

  }

  #menu-right-group {
    width: unset !important;
  }
}

.icon-certificate{
      padding-top: 6px;
}
</style>
